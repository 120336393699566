import React, { useState } from "react";
import { Layout, Modal } from 'antd';
import { connect, useSelector } from "react-redux";
import UserInfo from "components/UserInfo";
import HorizontalNav from "../HorizontalNav";
import { useHistory, Link } from "react-router-dom";
import { switchLanguage, toggleCollapsedSideNav } from "../../../appRedux/actions/Setting";
import moment from 'moment';
import { TAB_SIZE } from "../../../constants/ThemeSetting";
import HamburgerMenu from "../hamburger/Hamburger-menu";

const { Header } = Layout;
const InsideHeader = () => {
  const userRoles = JSON.parse(sessionStorage.getItem("user_Roles"));
  const history = useHistory();
  const { width, themeType } = useSelector(({ settings }) => settings);
  const skillLastUpdated = JSON.parse(sessionStorage.getItem('skillLastUpdated'));

  let pastQuarter = moment(new Date()).subtract(6, 'months');
  let flag = false;
  if ((skillLastUpdated && moment(skillLastUpdated).isAfter(pastQuarter)) || userRoles.some((role) => role.id === 9)) {
  } else {
    flag = true;
  }

  const [updateSkill, setUpdateSkill] = useState(flag);
  const [showHamburger, setShowHamburger] = useState(true);

  const onHamburger = () => {
    setShowHamburger(!showHamburger)
  }
  const onHideMenu = () => {
    setShowHamburger(true)
  }

  return (
    <div className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal sticky-top">
      <Header
        className="gx-header-horizontal-main logoHeader">
        <div className="gx-container-fluid">
          <div className="gx-header-horizontal-main-flex">

            <div className="geminiHeaderLogo">
              <Link to={userRoles.some((role) => role.id === 9) ? "/dashboard/customer" : "/dashboard/myaccount"} >
                <img
                  src={require('../../../assets/images/Gemini-Consulting-Services-logo.png')}
                  alt='gemini logo'
                />
              </Link>
            </div>

            <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e gx-ml-auto">
              {showHamburger ? <i className="gx-icon-btn icon icon-menu"
                onClick={() =>
                  onHamburger()
                }
              /> : <i className="gx-icon-btn icon icon-close"
                onClick={() =>
                  onHamburger()
                }
              />}

              {!showHamburger ? <HamburgerMenu onSelect={() => onHideMenu()} /> : ""}

            </div>
            {history.location.pathname === "/serviceRequest" ?
              <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block menuCss" style={{ width: "70%", textAlign: "center" }}>
                <p style={{ color: "#FFFFFF !important", paddingBottom: 0, marginBottom: 0, fontSize: "25px" }}>Service Request</p>
              </div>
              :
              <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block menuCss">
                <HorizontalNav />
              </div>
            }
            <ul className={width < TAB_SIZE ? "gx-header-notifications" : "gx-header-notifications gx-ml-auto"}>
              <li className="gx-user-nav">
                {history.location.pathname !== "/serviceRequest" && <UserInfo />}
              </li>
            </ul>
          </div>
        </div>
      </Header>

      <Modal closable={false} centered keyboard={false} maskClosable={false} title={"Remainder To Update Skills"}
        visible={updateSkill} okText="Update Skill"
        onOk={() => {
          history.push('/dashboard/myaccount');
          setUpdateSkill(false);
        }}
        onCancel={() => { setUpdateSkill(false); }}
        cancelButtonProps={{
          disabled: true,
          className: 'cancelBtnHide'
        }}
        wrapClassName={"modal-ticket-update"}>
        Dear <b>Geminite</b>, It's time to update your new skills and experience in the Skill Base repository.<br />
        <br /> <b>Skills have to be updated every 6 months.</b>
        <br /><br />
        Note: Please ignore this message incase you updated.
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ settings, common }) => {
  const { navCollapsed } = common;
  const { locale } = settings;
  return { locale, navCollapsed }
};
export default connect(mapStateToProps, { toggleCollapsedSideNav, switchLanguage })(InsideHeader);