import React from "react";
import { useDispatch } from "react-redux";
import { Avatar, Popover, Tooltip } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { LogoutOutlined } from '@ant-design/icons'
import {Link} from "react-router-dom";

const UserInfo = () => {

  const dispatch = useDispatch();
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'))
  const userRoles = JSON.parse(sessionStorage.getItem("user_Roles"));
  const userMenuOptions = (
    <ul className="gx-user-popover">
      {userRoles.some((role) => role.id === 9) ? (<></>) : (<li><span className="reset_pwd"><Link to="/dashboard/myaccount"><i className="icon icon-profile" />&nbsp;My Account</Link></span></li>)}
      {/*<li>Connections</li>*/}
      <li>
        <div><span className="reset_pwd"> <Link to="/userAuth/ResetPassword"><i className="icon icon-reset-password" />&nbsp;Reset Password</Link></span> </div>
      </li>
      <li onClick={() => dispatch(userSignOut())}><LogoutOutlined/>&nbsp;Logout</li>
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
      trigger="click">
      {(() => {
        try {
          return <Tooltip placement="rightTop" title={userDetails.displayNameTx?userDetails.displayNameTx:""}><Avatar className="gx-size-40 gx-pointer gx-mr-3" src={require(`../../assets/images/avatar/${userDetails.employeeId.toLowerCase()}.png`)} /></Tooltip>
        } catch (err) {
          try {
            return <Tooltip placement="rightTop" title={userDetails.displayNameTx?userDetails.displayNameTx:""}><Avatar className="gx-size-40 gx-pointer gx-mr-3" src={require(`../../assets/images/avatar/profile.png`)} /></Tooltip>
          } catch (err) {
            return <li><span className="ant-avatar gx-border gx-border-grey gx-bg-transparent gx-text-grey gx-d-flex gx-align-items-center gx-justify-content-center"><i
            className="icon icon-add" /></span></li>
          }
        }
      })()}
    </Popover>
  )

}

export default UserInfo;
