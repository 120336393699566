import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { MinusCircleOutlined } from '@ant-design/icons';
import {
  Row, Col, Form, Input, Select, Radio, InputNumber, DatePicker,
  Button, Card, Table, message, Tabs, Breadcrumb, Collapse, Spin 
} from "antd";
import { API_URL } from "constants/APIConfig";
import { useHistory } from "react-router-dom";
import Auxiliary from "util/Auxiliary";

const { Option } = Select;
const RadioGroup = Radio.Group;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Panel } = Collapse;

const EditProjects = (props) => {
  const [form] = Form.useForm()
  const history = useHistory()
  const token = sessionStorage.getItem("token")
  const [users, setUsers] = useState([])
  const [clients, setClients] = useState([])
  const [currency, setCurrency] = useState([])
  const [projecttypes, setProjectTypes] = useState([])
  const [techList, setTechList] = useState([])
  const [industryList, setIndustryList] = useState([])
  const [sectorList, setSectorList] = useState([])
  const [projectStatusList, setProjectStatusList] = useState([])
  const [priorityValue, setPriorityValue] = useState(1)
  const [isBillable, setIsBillable] = useState(0)
  const [isClosed, setIsclosed] = useState(true)
  const [plannedEffortsList, setPlannedEffortsList] = useState([])
  const [plannedEffortsTableList, setPlannedEffortsTableList] = useState([])
  const engagementTypeList = ["Offshore Shared", "Offshore Dedicated", "Onsite"]
  const [allSkills, setAllSkills] = useState([])
  const [detailsError, setDetailsError] = useState('')
  const [plannedEffortsError, setPlannedEffortsError] = useState('')
  const [resourceLoadingError, setResourceLoadingError] = useState('')
  const [financialError, setFinancialError] = useState('')
  const [resourceOnBoardList, setResourceOnBoardList] = useState([])
  const [resourceOnBoardTableList, setResourceOnBoardTableList] = useState([])
  const [allDepartment, setAllDepartment] = useState([])
  const [allUsersAllSkillList, setAllUsersAllSkillList] = useState([])
  const [projectAssignedUsers, setProjectAssignedUsers] = useState([])
  const [stakeHoldersTable, setStakeHoldersTable] = useState([]);
  const [stakeHolders, setStakeHolders] = useState({ prjmgr: null, acctmgr: null, pmo: null, prjinit: null });
  const [loading, setLoading] = useState(false)

  const handleRemovePlannedEffortClick = index => {
    const list = [...plannedEffortsList];
    list.splice(index, 1);
    setPlannedEffortsList(list);
  }

  const handleRemoveResourceOnBoardClick = index => {
    const list = [...resourceOnBoardList];
    list.splice(index, 1);
    setResourceOnBoardList(list);
  }

  const onChangeWDHC = (index, e, key) => {
    const list = [...plannedEffortsList];
    list[index][key] = e.target.value;
    if (key === 'costPerHour' || key === 'totalHours') {
      if (list[index]['costPerHour'] && list[index]['totalHours']) {
        list[index]['totalCost'] = list[index]['costPerHour'] * list[index]['totalHours']
      }
    }
    setPlannedEffortsList(list);
  }

  const onChagngeHours = (index, e) => {
    const list = [...resourceOnBoardList];
    list[index]['hours'] = e.target.value;
    setResourceOnBoardList(list);
  }

  const onChagngeComments = (index, e) => {
    const list = [...resourceOnBoardList];
    list[index]['comments'] = e.target.value;
    setResourceOnBoardList(list);
  }

  const onChangeDate = (index, e, key) => {
    const list = [...resourceOnBoardList];
    list[index][key] = e
    setResourceOnBoardList(list);
  }

  const onChangeBillable = (e) => {
    setIsBillable(e.target.value);
  }

  const onPriorityChange = (e) => {
    setPriorityValue(e.target.value);
  }

  const handleIsclosed = (value) => {
    if (value === 3 || value === 4) {
      setIsclosed(false);
    } else {
      setIsclosed(true);
    }
  }

  const addPlannedEfforts = () => {
    setPlannedEffortsList([...plannedEffortsList, {
      resourceType: "", subSkillId: "", engagementType: "",
      totalWeeks: "", totalDays: "", totalHours: "", costPerHour: "", totalCost: "", index: new Date().getTime()
    }]);
  }

  const addResourceOnBoard = () => {
    setResourceOnBoardList([...resourceOnBoardList,
    {
      departmentId: undefined, skillId: undefined, userId: undefined, startDate: "", endDate: "", hours: "", requestedById: undefined, comments: "",
      resources: [], allDepartments: [...allDepartment], allSkills: [], index: new Date().getTime()
    }]);
  }

  const getAllSkills = () => {
    axios.get(API_URL + '/api/skillbase/subskills/getAllSubSkills',
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        setAllSkills(response.data.data);
      })
      .catch(error => {
        console.log(error)
      });
  }

  const handleResourceType = (value, index) => {
    const list = [...plannedEffortsList];
    list[index]['resourceType'] = value;
    setPlannedEffortsList(list);
  }

  const handleEngagementType = (value, index) => {
    const list = [...plannedEffortsList];
    list[index]['engagementType'] = value;
    setPlannedEffortsList(list);
  }

  const handleSkill = (value, index) => {
    const list = [...plannedEffortsList];
    list[index]['subSkillId'] = value;
    setPlannedEffortsList(list);
  }

  const handleDSUR = (value, index, key) => {
    const list = [...resourceOnBoardList];
    list[index][key] = value;
    if (key === 'departmentId') {
      let skillNameSet = new Set()
      let skillSet = new Set()
      allUsersAllSkillList.forEach(obj => {
        if (obj.assignedSkill.sb_department.id === value) {
          if (!skillNameSet.has(obj.assignedSkill.sb_skill.nameTx)) {
            skillNameSet.add(obj.assignedSkill.sb_skill.nameTx)
            skillSet.add(obj.assignedSkill.sb_skill)
          }
        }
      })

      list[index]['allSkills'] = Array.from(skillSet)
      list[index]['skillId'] = undefined;
      list[index]['userId'] = undefined;
      list[index]['resources'] = []
    } else if (key === 'skillId') {
      let resourceNameSet = new Set()
      let resourceSet = new Set()
      allUsersAllSkillList.forEach(obj => {
        if (obj.assignedSkill.sb_skill.id === value) {
          if (!resourceNameSet.has(obj.assignedUser.displayNameTx)) {
            resourceNameSet.add(obj.assignedUser.displayNameTx)
            resourceSet.add(obj.assignedUser)
          }
        }
      })
      list[index]['userId'] = undefined;
      list[index]['resources'] = Array.from(resourceSet)
    }
    setResourceOnBoardList(list);
  }

  const onFinish = () => {
    if (validatePlannedEfforts()) {
      return
    }
    if (validateResourceLoading()) {
      return
    }
    let prjDetdata = {};
    const dates = form.getFieldValue("duration");
    let billType = +form.getFieldValue("billtype");
    if (isBillable == 0) {
      billType = 6;
    }

    let newProjectUsers = []
    let userIds = []
    projectAssignedUsers.forEach(u => { userIds.push(u.id) });
    resourceOnBoardList.forEach(onBoard => {
      if (userIds.indexOf(onBoard.userId) < 0 && newProjectUsers.indexOf(onBoard.userId) < 0) {
        newProjectUsers.push(onBoard.userId)
      }
    })
    let stakeHoldersArr = []
    if (form.getFieldValue("prjmgr") != stakeHolders.prjmgr) {
      stakeHoldersArr.push({
        managerType: 'Project Manager',
        fromManagerId: stakeHolders.prjmgr, toManagerId: form.getFieldValue("prjmgr")
      })
    }
    if (form.getFieldValue("acctmgr") != stakeHolders.acctmgr) {
      stakeHoldersArr.push({
        managerType: 'Account Manager',
        fromManagerId: stakeHolders.acctmgr, toManagerId: form.getFieldValue("acctmgr")
      })
    }
    if (form.getFieldValue("prjinit") != stakeHolders.prjinit) {
      stakeHoldersArr.push({
        managerType: 'Project Initiator',
        fromManagerId: stakeHolders.prjinit, toManagerId: form.getFieldValue("prjinit")
      })
    }
    if (form.getFieldValue("pmo") != stakeHolders.pmo) {
      stakeHoldersArr.push({
        managerType: 'Project Coordinator',
        fromManagerId: stakeHolders.pmo, toManagerId: form.getFieldValue("pmo")
      })
    }

    prjDetdata = {
      name: form.getFieldValue("prjname"),
      shortName: form.getFieldValue("shortname"),
      projectCode: form.getFieldValue("projectcode"),
      projectType: form.getFieldValue("prjtype"),
      invoiceCustomerId: form.getFieldValue("client"),
      startDate: dates[0].format('YYYY-MM-DD'),
      endDate: dates[1].format('YYYY-MM-DD'),
      estimatedHours: form.getFieldValue("estdhrs"),
      billableType: billType,
      billability: form.getFieldValue("billable"),
      priority: form.getFieldValue("prjpriority"),
      projectManager: form.getFieldValue("prjmgr"),
      accountManager: form.getFieldValue("acctmgr"),
      projectCoordinator: form.getFieldValue("pmo"),
      initiator: form.getFieldValue("prjinit"),
      statusId: form.getFieldValue("prjstatus"),
      industryType: form.getFieldValue("indus"),
      sector: form.getFieldValue("sector"),
      users: form.getFieldValue("users"),
      description: form.getFieldValue("notes"),
      scope: form.getFieldValue("scope"),
      currency: form.getFieldValue("currency"),
      costApproved: form.getFieldValue("amount"),
      budget: form.getFieldValue("amount"),
      poNumber: form.getFieldValue("pono"),
      poDate: form.getFieldValue("podate") ? form.getFieldValue("podate").format('YYYY-MM-DD') : null,
      progress: form.getFieldValue("progress"),
      closedDate: form.getFieldValue("closeddate"),
      plannedEffortsList: plannedEffortsList,
      resourceOnBoardList: resourceOnBoardList,
      newProjectUsers: newProjectUsers,
      stakeHolders: stakeHoldersArr,
      technologies: form.getFieldValue("tech")
    };

    axios
      .put(
        API_URL + `/api/project/v2/update/${props.match.params.id}`,
        prjDetdata,
        {
          headers: {
            "x-access-token": `${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then(() => {
        message.success("Project updated successfully");
        history.push("/master/projects/manage");
      })
      .catch((error) => {
        console.log(error);
        message.error("Error in updating project", error);
      });
  }

  const onFinishFailed = (v) => {
    let fieldNames = []
    v.errorFields.forEach(err => {
      fieldNames.push(err.name[0])
    })
    let flag = false
    if (fieldNames.some(r => ['prjname', 'shortname', 'projectcode', 'prjtype', 'client', 'duration', 'estdhrs', 'prjpriority', 'prjstatus', 'closeddate', 'tech', 'indus', 'sector', 'scope'].includes(r))) {
      setDetailsError('tabError')
      flag = true
    } else {
      setDetailsError('')
    }
    if (v.values.billable === 1) {
      if (fieldNames.some(r => ['billable', 'billtype', 'pono', 'podate', 'amount'].includes(r))) {
        setFinancialError('tabError')
        flag = true
      } else {
        setFinancialError('')
      }
    } else {
      setFinancialError('')
    }

    if (flag) {
      message.error(`Please enter the mandatory fields.`);
    }
  }

  const validatePlannedEfforts = () => {
    let flag = false
    plannedEffortsList.forEach(effort => {
      if (effort.resourceType === '' || effort.engagementType === '' || effort.subSkillId === '') {
        flag = true
      }
    })
    if (flag) {
      setPlannedEffortsError('tabError')
      message.error(`Please enter the mandatory fields.`);
    } else {
      setPlannedEffortsError('')
    }
    return flag;
  }

  const validateResourceLoading = () => {
    let flag = false
    let msg = 'Please enter the mandatory fields.'
    resourceOnBoardList.forEach(resource => {
      if (resource.departmentId === '' || resource.skillId === '' || resource.userId === ''
        || resource.startDate === '' || resource.endDate === '' || resource.hours === ''
        || resource.requestedById === '' || resource.comments === '') {
        flag = true
      } else if (resource.startDate !== '' && resource.endDate !== '') {
        if (!moment(resource.endDate).isAfter(moment(resource.startDate))) {
          flag = true
          msg = msg + ' startDate must be less than endDate.'
        }
      }
    })
    if (flag) {
      setResourceLoadingError('tabError')
      message.error(msg);
    } else {
      setResourceLoadingError('')
    }
    return flag;
  }

  const getUserList = () => {
    axios
      .get(API_URL + "/api/users/getall", {
        headers: {
          "x-access-token": `${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setUsers(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getClientList = () => {
    axios
      .get(API_URL + "/api/invoice_customers/all?limit=500&offset=0", {
        headers: {
          "x-access-token": `${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setClients(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getTechList = () => {
    axios
      .get(API_URL + "/api/technology/all", {
        headers: {
          "x-access-token": `${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setTechList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getIndustriesList = () => {
    axios
      .get(API_URL + "/api/industry/all", {
        headers: {
          "x-access-token": `${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setIndustryList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getSectorList = () => {
    axios
      .get(API_URL + "/api/sector/all", {
        headers: {
          "x-access-token": `${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setSectorList(response.data.data);
      })
      .catch((error) => {
        console.log(error)
      });
  }

  const getCurrencyList = () => {
    axios
      .get(API_URL + "/api/currency/all", {
        headers: {
          "x-access-token": `${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setCurrency(response.data.data);
      })
      .catch((error) => {
        console.log(error)
      });
  }

  const getProjectTypeList = () => {
    axios
      .get(API_URL + "/api/projecttype/all", {
        headers: {
          "x-access-token": `${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setProjectTypes(response.data.data);
      })
      .catch((error) => {
        console.log(error)
      });
  }

  const getProjectStatusList = () => {
    axios
      .get(API_URL + "/api/projectstatus/all", {
        headers: {
          "x-access-token": `${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setProjectStatusList(response.data.data);
      })
      .catch((error) => {
        console.log(error)
      });
  }

  const getProjectbyId = () => {
    setLoading(true)
    axios
      .get(API_URL + `/api/project/v2/get/${props.match.params.id}?t=${new Date().getTime()}`, {
        headers: {
          "x-access-token": `${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        getUIProjectbyId(response.data.data);
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error);
      });
  }

  const getUIProjectbyId = (projectDetails) => {

    if (projectDetails.ProjectPlannedEfforts && projectDetails.ProjectPlannedEfforts.length != 0) {
      projectDetails.ProjectPlannedEfforts.forEach(s => {
        s.key = s.id
      })
      setPlannedEffortsTableList(projectDetails.ProjectPlannedEfforts)
    }

    if (projectDetails.ResourceOnBoard && projectDetails.ResourceOnBoard.length != 0) {
      projectDetails.ResourceOnBoard.forEach(s => {
        s.key = s.id
      })
      setResourceOnBoardTableList(projectDetails.ResourceOnBoard)
    }

    let projectTechnologies = []
    if (projectDetails.projectTechnologies && projectDetails.projectTechnologies.length != 0) {
      projectDetails.projectTechnologies.forEach(s => {
        projectTechnologies.push(s.technologyId)
      })
    }

    let projectUsers = [];
    let assignedUsersSet = new Set()
    if (projectDetails.projectUsers && projectDetails.projectUsers.length != 0) {
      let assignedUsers = []
      projectDetails.projectUsers.forEach(s => {
        if (!assignedUsersSet.has(s.userIdNm)) {
          assignedUsersSet.add(s.userIdNm)
          assignedUsers.push(s.assignedResource)
          if (s.activeYn) {
            projectUsers.push(s.userIdNm)
          }
        }
      })
      setProjectAssignedUsers(assignedUsers)
    }

    if (projectDetails.projectStakeHolders && projectDetails.projectStakeHolders.length > 0) {
      projectDetails.projectStakeHolders.forEach(s => { s.key = s.id })
      setStakeHoldersTable(projectDetails.projectStakeHolders)
    }

    setIsBillable(projectDetails.billability)
    form.setFieldsValue({
      prjname: projectDetails.name ? projectDetails.name : null,
      shortname: projectDetails.shortName ? projectDetails.shortName : null,
      projectcode: projectDetails.projectCode
        ? projectDetails.projectCode
        : null,
      prjtype: projectDetails.projectType ? projectDetails.projectType : null,
      client: projectDetails.invoiceCustomerId
        ? projectDetails.invoiceCustomerId
        : null,
      estdhrs: projectDetails.estimatedHours
        ? projectDetails.estimatedHours
        : null,
      billtype: projectDetails.billableType
        ? projectDetails.billableType
        : null,
      billable: projectDetails.billability,
      prjpriority: projectDetails.priority ? projectDetails.priority : null,
      prjmgr: projectDetails.projectManager
        ? projectDetails.projectManager
        : null,
      acctmgr: projectDetails.accountManager
        ? projectDetails.accountManager
        : null,
      prjinit: projectDetails.initiator ? projectDetails.initiator : null,
      pmo: projectDetails.projectCoordinator
        ? projectDetails.projectCoordinator
        : null,
      prjstatus: projectDetails.statusId ? projectDetails.statusId : null,
      indus: projectDetails.industryType ? projectDetails.industryType : null,
      sector: projectDetails.sector ? projectDetails.sector : null,
      users: projectUsers,
      notes: projectDetails.description ? projectDetails.description : null,
      tech: projectTechnologies,
      duration: [
        projectDetails.startDate ? moment(projectDetails.startDate) : null,
        projectDetails.endDate ? moment(projectDetails.endDate) : null,
      ],
      scope: projectDetails.scope ? projectDetails.scope : null,
      currency: projectDetails.currency ? (isNaN(projectDetails.currency) ? null : parseInt(projectDetails.currency)) : null,
      amount: projectDetails.costApproved ? projectDetails.costApproved : null,
      pono: projectDetails.poNumber ? projectDetails.poNumber : null,
      podate: projectDetails.poDate ? moment(projectDetails.poDate) : null,
      progress: projectDetails.progress ? projectDetails.progress : 0,
      closeddate: projectDetails.closedDate
        ? moment(projectDetails.closedDate)
        : null,
    });
    setStakeHolders({
      prjmgr: projectDetails.projectManager ? projectDetails.projectManager : null,
      acctmgr: projectDetails.accountManager ? projectDetails.accountManager : null,
      pmo: projectDetails.projectCoordinator ? projectDetails.projectCoordinator : null,
      prjinit: projectDetails.initiator ? projectDetails.initiator : null
    })
  }

  const plannedEffortsTableColumns = [
    {
      title: 'Resource Type', dataIndex: 'resourceType', width: 150,
    },
    {
      title: 'Skill', dataIndex: 'subSkillId', width: 150, render: function (text, record) {
        return record.assignedSkill.nameTx;
      }
    },
    {
      title: 'Engagement', dataIndex: 'engagementType', width: 150,
    },
    {
      title: 'Total Weeks', dataIndex: 'totalWeeks', width: 150,
    },
    {
      title: 'Total Days', dataIndex: 'totalDays', width: 150,
    },
    {
      title: 'Total Hours', dataIndex: 'totalHours', width: 150,
    },
    { title: 'Cost Per Hour', width: 100, dataIndex: 'costPerHour' },
  ]

  const resourceOnBoardingTableColumns = [
    {
      title: 'Department', width: 150, render: function (text, record) {
        return record.assignedDepartment.nameTx;
      }
    },
    {
      title: 'Skill', width: 150, render: function (text, record) {
        return record.assignedSkill.nameTx;
      }
    },
    {
      title: 'Resource', width: 150, render: function (text, record) {
        return record.assignedUser.displayNameTx;
      }
    },
    {
      title: 'Start Date', dataIndex: 'startDate', width: 150,
    },
    {
      title: 'End Date', dataIndex: 'endDate', width: 150,
    },
    {
      title: 'Planned Hours', dataIndex: 'hours', width: 150,
    },
    {
      title: 'Requested By', width: 100, render: function (text, record) {
        return record.requestedByUser.displayNameTx;
      }
    },
    { title: 'Comments', width: 100, dataIndex: 'comments' },
  ]

  const stakeHoldersTableCol = [
    {
      title: 'Manager Type', dataIndex: 'managerType', width: 150,
    },
    {
      title: 'From Manager', width: 150, render: function (text, record) {
        return record.fromManager.displayNameTx
      }
    },
    {
      title: 'To Manager', width: 150, render: function (text, record) {
        return record.toManager.displayNameTx
      }
    },
    {
      title: 'Modified Date', width: 150, render: (text, record) => {
        return moment(record.modified).format('lll')
      }
    },
  ]

  const getAllUsersAllSkills = () => {
    axios.get(API_URL + `/api/user_skills/fetchAllUsersAllSkills?t=${new Date().getTime()}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (response.data && response.data.data) {
          response.data.data.forEach((d, i) => { d['key'] = i })
          setAllUsersAllSkillList(response.data.data)

          let departmentSet = new Set()
          let depNameSet = new Set()

          response.data.data.forEach(obj => {
            if (!depNameSet.has(obj.assignedSkill.sb_department.nameTx)) {
              departmentSet.add(obj.assignedSkill.sb_department)
              depNameSet.add(obj.assignedSkill.sb_department.nameTx)
            }
          })
          setAllDepartment(Array.from(departmentSet));
        }
      })
      .catch(error => {
      });
  }

  useEffect(() => {
    getUserList();
    getClientList();
    getProjectTypeList();
    getProjectStatusList();
    getCurrencyList();
    getTechList();
    getSectorList();
    getIndustriesList();
    getProjectbyId();
    getAllSkills();
    getAllUsersAllSkills()
  }, []);

  return (
    <Auxiliary>
      <Breadcrumb>
        <Breadcrumb.Item>Projects</Breadcrumb.Item>
        <Breadcrumb.Item className={'text-helight'}><i className="icon icon-check-circle-o" /> Edit Project </Breadcrumb.Item>
      </Breadcrumb>
      <br />
      <Spin size="large" spinning={loading}>
      <Form
        form={form}
        initialValues={{ remember: true }}
        name="prjdetails"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="inline"
        align="center" 
      >
        <Card style={{ width: 1300 }}>
          <Tabs tabBarStyle={{ color: "red" }}>
            <Tabs.TabPane key="1" defaultActiveKey="1" tab={<div className={detailsError}>DETAILS</div>}>
              <Row>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left">Name</h6>
                    <Form.Item
                      name="prjname"
                      rules={[
                        {
                          required: true,
                          message: "Enter project name",
                        },
                      ]}
                    >
                      <Input placeholder="Project Name" />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <h6 align="left">Short Name</h6>
                  <div className="gx-form-row0">
                    <Form.Item
                      name="shortname"
                      rules={[
                        {
                          required: true,
                          message: "Enter Short Name",
                        },
                      ]}
                    >
                      <Input placeholder="Short Name" />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <h6 align="left">Project Code</h6>
                  <div className="gx-form-row0">
                    <Form.Item
                      name="projectcode"
                      rules={[
                        {
                          required: true,
                          message: "Enter project code",
                        },
                      ]}
                    >
                      <Input placeholder="Project Code" />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left">Type</h6>
                    <Form.Item
                      name="prjtype"
                      rules={[
                        {
                          required: true,
                          message: "Enter project type",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select a Value"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {projecttypes.length
                          ? projecttypes.map((projecttype, index) => (
                            <Option key={index} value={projecttype.id}>
                              {projecttype.typeTx}
                            </Option>
                          ))
                          : null}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left">Client</h6>
                    <Form.Item
                      name="client"
                      rules={[
                        {
                          required: true,
                          message: "Enter Client",
                        },
                      ]}>
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select a Value"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {clients.length
                          ? clients.map((client, index) => (
                            <Option key={index} value={client.id}>
                              {client.organization}
                            </Option>
                          ))
                          : null}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left">Duration</h6>
                    <Form.Item
                      name="duration"
                      rules={[
                        {
                          required: true,
                          message: "Enter Duration",
                        },
                      ]}>
                      <RangePicker className="gx-mb-3 gx-w-100" format="DD-MM-YYYY" />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left">Estd Hours</h6>
                    <Form.Item
                      name="estdhrs"
                      rules={[
                        {
                          required: true,
                          message: "Enter estimated hours",
                        },
                      ]}>
                      <Input placeholder="Estimated Hours" />
                    </Form.Item>
                  </div>
                </Col>

                <Col lg={8} md={8} sm={12} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left">Priority</h6>
                    <Form.Item
                      name="prjpriority"
                      rules={[
                        {
                          required: true,
                          message: "Enter project priority",
                        },
                      ]}>
                      <RadioGroup
                        name="priority"
                        onChange={(e) => onPriorityChange(e)}
                        value={priorityValue}
                      >
                        <Radio value={1}>High</Radio>
                        <Radio value={2}>Medium</Radio>
                        <Radio value={3}>Low</Radio>
                      </RadioGroup>
                    </Form.Item>
                  </div>
                </Col>

                <Col lg={8} md={8} sm={12} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left">Status</h6>
                    <Form.Item
                      name="prjstatus"
                      rules={[
                        {
                          required: true,
                          message: "Enter project Status",
                        },
                      ]}>
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select a Value"
                        optionFilterProp="children"
                        onChange={handleIsclosed}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {projectStatusList.length
                          ? projectStatusList.map((projectStatus, index) => (
                            <Option key={index} value={projectStatus.id}>
                              {projectStatus.statusTx}
                            </Option>
                          ))
                          : null}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left">Closed_Date</h6>
                    <Form.Item
                      name="closeddate"
                      rules={[
                        {
                          required: Boolean(false),
                          message: "Enter Closed Date",
                        },
                      ]}>
                      <DatePicker disabled={isClosed} style={{ width: "90%" }} format="DD-MM-YYYY" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Card style={{ background: "#f9f9f9", width: 1050 }}>
                <Row>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <h6 align="left">Technology</h6>
                      <Form.Item
                        name="tech"
                        rules={[
                          {
                            required: true,
                            message: "Enter Technologies",
                          },
                        ]}>
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select a Value"
                          mode="multiple"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {techList.length
                            ? techList.map((tech, index) => (
                              <Option key={index} value={tech.id}>
                                {tech.nameTx}
                              </Option>
                            ))
                            : null}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>

                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <h6 align="left">Industry</h6>
                      <Form.Item
                        name="indus"
                        rules={[
                          {
                            required: true,
                            message: "Enter Industry",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select a Value"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {industryList.map((industry, index) => (
                            <Option key={index} value={industry.nameTx}>
                              {industry.nameTx}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <h6 align="left">Sector</h6>
                      <Form.Item
                        name="sector"
                        rules={[
                          {
                            required: true,
                            message: "Enter sector",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select a Value"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {sectorList.map((sector, index) => (
                            <Option key={index} value={sector.nameTx}>
                              {sector.nameTx}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Card>
              <Row>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left">Scope</h6>
                    <Form.Item
                      name="scope"
                      rules={[
                        {
                          required: true,
                          message: "Enter Scope",
                        },
                      ]}
                    >
                      <TextArea rows={8} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab={<div className={plannedEffortsError}>PLANNED EFFORTS </div>} key="2" defaultActiveKey="1" >
              <div hidden={plannedEffortsList.length ? false : true} className="gx-form-row0 blue-color"><b>Add Planned Efforts</b><hr />
              </div>
              <Row hidden={plannedEffortsList.length ? false : true} className="align_center m_0 spacing_bottom timesheetBodyBlock border_bottom">
                <Col lg={3} md={3} sm={3} xs={3}><b>Resource Type</b></Col>
                <Col lg={4} md={4} sm={4} xs={4}><b>Skill</b></Col>
                <Col lg={3} md={3} sm={3} xs={3}><b>Engagement</b></Col>
                <Col lg={2} md={2} sm={2} xs={2}><b>Total Weeks</b></Col>
                <Col lg={2} md={2} sm={2} xs={2}><b>Total Days</b></Col>
                <Col lg={2} md={2} sm={2} xs={2}><b>Total Hours</b></Col>
                <Col lg={2} md={2} sm={2} xs={2}><b>Cost Per Hour</b></Col>
                <Col lg={3} md={3} sm={3} xs={3}><b>Total Cost</b></Col>
                <Col lg={3} md={3} sm={3} xs={3}><b>Action</b></Col>
              </Row>
              {plannedEffortsList.map((x, i) => {
                return (
                  <Row key={x.index} className="m_0 spacing_bottom timesheetBodyBlock border_bottom">
                    <Col lg={3} md={3} sm={3} xs={3} className="borderRight">
                      <Select
                        title="Select The Resource Type"
                        style={{ width: "100%" }}
                        placeholder="Select The Resource Type"
                        onChange={e => handleResourceType(e, i)}
                      >
                        <Option value={"Junior"}>Junior</Option>
                        <Option value={"Senior"}>Senior</Option>
                      </Select>
                    </Col>
                    <Col lg={4} md={4} sm={4} xs={4} className="borderRight">
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select The Skill"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={e => handleSkill(e, i)}
                      >
                        {allSkills.length
                          ? allSkills.map((skill, index) => (
                            <Option key={index} value={skill.id}>
                              {skill.nameTx}
                            </Option>
                          ))
                          : null}
                      </Select></Col>
                    <Col lg={3} md={3} sm={3} xs={3} className="borderRight">
                      <Select
                        title="Select The Engagement Type"
                        style={{ width: "100%" }}
                        placeholder="Select The Engagement Type"
                        onChange={e => handleEngagementType(e, i)}
                      >
                        {engagementTypeList.length
                          ? engagementTypeList.map((engagement, index) => (
                            <Option key={engagement} value={engagement} style={{ width: "160px" }}>
                              {engagement}
                            </Option>
                          ))
                          : null}
                      </Select></Col>
                    <Col lg={2} md={2} sm={2} xs={2} className="borderRight"><Input onChange={e => onChangeWDHC(i, e, 'totalWeeks')} value={x.totalWeeks} /></Col>
                    <Col lg={2} md={2} sm={2} xs={2} className="borderRight"><Input onChange={e => onChangeWDHC(i, e, 'totalDays')} value={x.totalDays} /></Col>
                    <Col lg={2} md={2} sm={2} xs={2} className="borderRight"><Input onChange={e => onChangeWDHC(i, e, 'totalHours')} value={x.totalHours} /></Col>
                    <Col lg={2} md={2} sm={2} xs={2} className="borderRight"><Input onChange={e => onChangeWDHC(i, e, 'costPerHour')} value={x.costPerHour} /></Col>
                    <Col lg={3} md={3} sm={3} xs={3} className="borderRight"><Input value={x.totalCost} disabled={true} /></Col>
                    <Col lg={3} md={3} sm={3} xs={3} className="borderRight total_value">
                      <MinusCircleOutlined onClick={() => handleRemovePlannedEffortClick(i)} />
                    </Col>
                  </Row>
                )
              })}
              <Row >
                <Col lg={24} md={24} sm={24} xs={24} >
                  <div className="newprojectBtn">
                    <Button type="text" onClick={() => addPlannedEfforts()} className="gx-mt-3 addBtn">
                      <i className="fa fa-plus" aria-hidden="true"></i>Add Efforts
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Collapse>
                    <Panel header="Existing Planned Efforts" key="1">
                      <Table columns={plannedEffortsTableColumns} dataSource={plannedEffortsTableList} pagination={{ pageSize: 10 }} scroll={{ x: 1050 }} />
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab={<div className={financialError}>FINANCIALS </div>} key="3" defaultActiveKey="1" >
              <Row>
                <Col lg={6} md={6} sm={12} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left">Billable</h6>
                    <Form.Item
                      name="billable"
                      rules={[
                        {
                          required: true,
                          message: "Enter project billable",
                        },
                      ]}
                    >
                      <RadioGroup
                        name="billable"
                        onChange={(e) => onChangeBillable(e)}
                        value={isBillable}
                      >
                        <Radio value={0}>Non Billable</Radio>
                        <Radio value={1}>Billable</Radio>
                      </RadioGroup>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left">Billing Type</h6>
                    <Form.Item
                      name="billtype"
                      rules={[
                        {
                          required: Boolean(isBillable),
                          message: "Enter project billable",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        disabled={!Boolean(isBillable)}
                        style={{ width: "100%" }}
                        placeholder="Select a Value"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value={1}>SOW</Option>
                        <Option value={2}>Monthly</Option>
                        <Option value={3}>Quaterly</Option>
                        <Option value={4}>Annually</Option>
                        <Option value={5}>Milestone</Option>
                        <Option value={6}>Internal</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={24}></Col>
                <Col lg={6} md={6} sm={12} xs={24}></Col>
              </Row>

              <Row>
                <Col lg={6} md={6} sm={12} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left">PO Number</h6>
                    <Form.Item
                      name="pono"
                      rules={[
                        {
                          required: Boolean(isBillable),
                          message: "Enter PO number",
                        },
                      ]}
                    >
                      <Input disabled={!Boolean(isBillable)} placeholder="PO #" />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left">PO Date</h6>
                    <Form.Item
                      name="podate"
                      rules={[
                        {
                          required: Boolean(isBillable),
                          message: "Enter PO Date",
                        },
                      ]}
                    >
                      <DatePicker
                        disabled={!Boolean(isBillable)} format="DD-MM-YYYY"
                        style={{ width: "90%" }}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left">PO Value</h6>
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <Form.Item
                          name="currency"
                          rules={[
                            {
                              required: Boolean(isBillable),
                              message: "Currency is required",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            disabled={!Boolean(isBillable)}
                            style={{width: '100%'}}
                            placeholder="Currency"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {currency.length
                              ? currency.map((curr, index) => (
                                <Option key={curr.id} value={curr.id}>
                                  {curr.codeTx}
                                </Option>
                              ))
                              : null}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <Form.Item
                          name="amount"
                          rules={[
                            {
                              required: Boolean(isBillable),
                              message: "Amount is required",
                            },
                          ]}
                        >
                          <InputNumber placeholder="Amount"
                            disabled={!Boolean(isBillable)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12} xs={24}></Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="STAKEHOLDERS" key="4" defaultActiveKey="1" >
              <Row>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left">Project Manager</h6>
                    <Form.Item
                      name="prjmgr"
                      rules={[
                        {
                          required: true,
                          message: "Enter Project Manager",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select a Value"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {users.length
                          ? users.map((user, index) => (
                            <Option key={index} value={user.id}>
                              {user.displayNameTx}
                            </Option>
                          ))
                          : null}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left">Account Manager</h6>
                    <Form.Item
                      name="acctmgr"
                      rules={[
                        {
                          required: true,
                          message: "Enter Account Manager",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select a Value"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {users.length
                          ? users.map((user, index) => (
                            <Option key={index} value={user.id}>
                              {user.displayNameTx}
                            </Option>
                          ))
                          : null}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left">Project Initiator</h6>
                    <Form.Item
                      name="prjinit"
                      rules={[
                        {
                          required: false,
                          message: "Enter Project Initiator",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select a Value"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {users.length
                          ? users.map((user, index) => (
                            <Option key={index} value={user.id}>
                              {user.displayNameTx}
                            </Option>
                          ))
                          : null}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left">Project Coordinator</h6>
                    <Form.Item
                      name="pmo"
                      rules={[
                        {
                          required: true,
                          message: "Enter Project Coordinator",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select a Value"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {users.length
                          ? users.map((user, index) => (
                            <Option key={index} value={user.id}>
                              {user.displayNameTx}
                            </Option>
                          ))
                          : null}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Collapse>
                    <Panel header="History" key="1">
                      <Table columns={stakeHoldersTableCol} dataSource={stakeHoldersTable} pagination={{ pageSize: 10 }} scroll={{ x: 1050 }} />
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab={<div className={resourceLoadingError}>RESOURCE LOADING </div>} key="5" defaultActiveKey="1" >
              <Row>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <div className="gx-form-row0 blue-color"><b>Active Resources To Submit The Timesheet</b>
                    <Form.Item
                      name="users">
                      <Select
                        mode="multiple"
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select a Value"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          return option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0;
                        }
                        }
                      >
                        {projectAssignedUsers.length
                          ? projectAssignedUsers.map((user, index) => (
                            <Option key={index} value={user.id}>
                              {user.displayNameTx}
                            </Option>
                          ))
                          : null}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row hidden={resourceOnBoardList.length ? false : true}><Col lg={24} md={24} sm={24} xs={24}>
                <div className="gx-form-row0 blue-color"><b>Add Resource</b></div>
              </Col></Row>
              <Row hidden={resourceOnBoardList.length ? false : true} className="align_center m_0 spacing_bottom timesheetBodyBlock border_bottom">
                <Col lg={3} md={3} sm={3} xs={3}><b>Department</b></Col>
                <Col lg={3} md={3} sm={3} xs={3}><b>Skill</b></Col>
                <Col lg={3} md={3} sm={3} xs={3}><b>Resource</b></Col>
                <Col lg={3} md={3} sm={3} xs={3}><b>Start Date</b></Col>
                <Col lg={3} md={3} sm={3} xs={3}><b>End Date</b></Col>
                <Col lg={2} md={2} sm={2} xs={2}><b>Planned Hours</b></Col>
                <Col lg={3} md={3} sm={3} xs={3}><b>Requested By</b></Col>
                <Col lg={3} md={3} sm={3} xs={3}><b>Comments</b></Col>
                <Col lg={1} md={1} sm={1} xs={1}></Col>
              </Row>
              {resourceOnBoardList.map((x, i) => {
                return (
                  <Row key={x.index} className="m_0 spacing_bottom timesheetBodyBlock border_bottom">
                    <Col lg={3} md={3} sm={3} xs={3} className="borderRight">
                      <Select
                      showSearch
                        style={{ width: '100%' }}
                        title="Select the Department"
                        placeholder="Select the Department"
                        onChange={e => handleDSUR(e, i, 'departmentId')}
                        dropdownMatchSelectWidth={false}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={
                          x.allDepartments.length ? x.allDepartments.map((department, index) => (
                            {value: department.id, label: department.nameTx}
                          )) : []
                        }
                      >
                      </Select>
                    </Col>
                    <Col lg={3} md={3} sm={3} xs={3} className="borderRight">
                      <Select
                      showSearch
                        style={{ width: '100%' }} title="Select the Skill"
                        placeholder="Select the Skill" onChange={e => handleDSUR(e, i, 'skillId')}
                        value={x.skillId}
                        dropdownMatchSelectWidth={false}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={
                          x.allSkills.length ? x.allSkills.map((skill, index) => (
                            {value: skill.id, label: skill.nameTx}
                          )) : []
                        }></Select>
                    </Col>
                    <Col lg={3} md={3} sm={3} xs={3} className="borderRight">
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        title="Select The Resource"
                        placeholder="Select The Resource"
                        optionFilterProp="children"
                        onChange={e => handleDSUR(e, i, 'userId')}
                        value={x.userId}
                        dropdownMatchSelectWidth={false}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={
                          x.resources.length ? x.resources.map((user, index) => (
                            {value: user.id, label: user.displayNameTx}
                          )) : []
                        }></Select>
                    </Col>
                    <Col lg={3} md={3} sm={3} xs={3} className="borderRight">
                      <DatePicker placeholder="Select Start Date" title="Select Start Date" value={x.startDate} format="DD-MM-YYYY" onChange={e => onChangeDate(i, e, 'startDate')} />
                    </Col>
                    <Col lg={3} md={3} sm={3} xs={3} className="borderRight">
                      <DatePicker placeholder="Select End Date" title="Select End Date" value={x.endDate} format="DD-MM-YYYY" onChange={e => onChangeDate(i, e, 'endDate')} />
                    </Col>
                    <Col lg={2} md={2} sm={2} xs={2} className="borderRight"><Input onChange={e => onChagngeHours(i, e)} value={x.hours} /></Col>
                    <Col lg={3} md={3} sm={3} xs={3} className="borderRight">
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        title="Select The RequestedBy"
                        placeholder="Select The RequestedBy"
                        optionFilterProp="children"
                        onChange={e => handleDSUR(e, i, 'requestedById')}
                        dropdownMatchSelectWidth={false}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={
                          users.length ? users.map((user, index) => (
                            {value: user.id, label: user.displayNameTx}
                          )) : []
                        }
                      >
                      </Select>
                    </Col>
                    <Col lg={3} md={3} sm={3} xs={3} className="borderRight">
                      <Input value={x.comments} onChange={e => onChagngeComments(i, e)} />
                    </Col>
                    <Col lg={1} md={1} sm={1} xs={1} className="borderRight total_value"><MinusCircleOutlined onClick={() => handleRemoveResourceOnBoardClick(i)} /></Col>
                  </Row>
                )
              })}
              <Row>
                <Col lg={24} md={24} sm={24} xs={24} >
                  <div className="newprojectBtn">
                    <Button type="text" onClick={() => addResourceOnBoard()} className="gx-mt-3 addBtn">
                      <i className="fa fa-plus" aria-hidden="true"></i>Add Resource
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Collapse>
                    <Panel header="Existing Resource" key="1">
                      <Table columns={resourceOnBoardingTableColumns} dataSource={resourceOnBoardTableList} pagination={{ pageSize: 10 }} scroll={{ x: 1050 }} />
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="NOTES" key="6" defaultActiveKey="1" >
              <Row>
                <Col lg={24} md={24} sm={24} xs={24}>
                  <div className="gx-form-row0">
                    <Form.Item
                      name="notes"
                      rules={[
                        {
                          required: false,
                          message: "Enter Description",
                        },
                      ]}
                    >
                      <TextArea rows={8} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
          <Row gutter={24}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <div className="gx-form-row0">
                <Form.Item>
                  <Button type="primary" className="add-btn" htmlType="submit">
                    Submit
                  </Button>
                  <Button
                    type="primary"
                    className="add-btn gx-mr-2"
                    onClick={() => history.push("/master/projects/manage")}>
                    Back
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Card>
      </Form>
      </Spin>
    </Auxiliary>
  );
};
export default EditProjects;