import React, { useEffect, useState } from "react";
import { Col, Row, Table, message, Button, Form, Input, Modal, Select } from "antd";
import axios from 'axios';
import Auxiliary from "../../../util/Auxiliary";
import { API_URL } from '../../../constants/APIConfig';
import { FileAddTwoTone } from '@ant-design/icons';
import moment from 'moment';
import 'moment/min/locales';

const { TextArea } = Input;

const CustomerHome = () => {
  const [taskForm] = Form.useForm()
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'))
  const token = sessionStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [userTaskList, setUserTaskList] = useState([])
  const [textAreaCount, setTextAreaCount] = React.useState(0);
  const [description, setDescription] = useState("");
  const [projects, setProjects] = useState([])
  const [projectId, setProjectId] = useState({})
  const [taskType, setTaskType] = useState('Incident')

  const tasksListCols = [
    {
      title: 'Name', dataIndex: 'name',
      render: (text, record) => {
        return (
          <div>
            <span> {text} </span>
          </div>
        );
      },
    },
    {
      title: 'Description', dataIndex: 'description', width: 300,
      render: (text, record) => {
        return (
          <div>
            <span> {text}</span>
          </div>
        );
      },
    },{
      title: 'Status', dataIndex: 'status', sorter: (a, b) => String(a.status).localeCompare(String(b.status)),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <div>
            <span> {text}</span>
          </div>
        );
      },
    },
    {
      title: 'Created Date', dataIndex: 'createdAt', width: 160,
      sorter: (a, b) => {
        return moment(a.createdAt).isAfter(moment(b.createdAt))},
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <div>
            <span> {record.createdAt ? moment(record.createdAt).format('lll') : null} </span>
          </div>
        );
      },
    },
  ]

  const openTaskForm = (v) => {
    setIsModalOpen(true)
    setTaskType(v)
  }

  const onProjectChange = value => {
    projects.forEach(p => {
      if(p.id === value) {
        setProjectId({id: value, name: p.name });
        getUserTasksList(value)
      }
    })
    
  }

  const createUserTask = (data) => {
    setLoading(true)
    if(taskType === 'CR') {
      data.isChangeRequest = true
    }
    axios.post(API_URL + `/api/customer/createTask`, data,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if(response.status === 200) {
          getUserTasksList(projectId.id)
          message.success("Task created successfully");
          taskForm.resetFields()
        }
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        message.error("Unexpected error occurred.");
        taskForm.resetFields()
      });
  }

  const handleDescription = (e) => {
    setDescription(e.target.value);
    setTextAreaCount(e.target.value.length);
  }

  const onTaskFormFinish = () => {
    let values = taskForm.getFieldsValue()
    values.projectId = projectId.id
    values.clientId = userDetails.id
    setIsModalOpen(false)
    createUserTask(values)
  }

  const getProjectList = () => {
    setLoading(true)
    axios.get(API_URL + `/api/customer/${userDetails.id}/projectlist?t=${new Date().getTime()}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setProjects(response.data.data);
        }
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.log(error);
      });
  }

  const getUserTasksList = (projectId) => {
    setLoading(true)
    axios.get(API_URL + `/api/customer/${userDetails.id}/${projectId}/taskslist?t=${new Date().getTime()}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (response.data && response.data.data) {
          response.data.data.forEach((d, i) => { d['key'] = i })
          setUserTaskList(response.data.data)
        }
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.log(error);
      });
  }

  useEffect(() => {
    getProjectList()
  }, [])
  return (
    <Auxiliary>
      <center className="gx-text-blue gx-pb-3 gx-pt-3"><h4><b>Tasks Dashboard</b></h4></center>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>

          <Row gutter={24}>
            <Col lg={8} md={6} sm={24} xs={24} >
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}  >
              <div className="gx-form-row0" >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select the Project"
                  optionFilterProp="children"
                  onChange={onProjectChange}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={
                    projects.length ? projects.map((pm, index) => (
                      {value: pm.id, label: pm.name}
                    )) : []
                  }
                >
                </Select>
              </div>
            </Col>
            <Col lg={10} md={6} sm={24} xs={24}>
              <Button type="link" className="scale-x" onClick={() => openTaskForm('Incident')} disabled={!projectId.id}>
                <FileAddTwoTone style={{ padding: '10px' }} disabled={!projectId.id}/> Incident</Button>

                <Button type="link" className="scale-x" onClick={() => openTaskForm('CR')} disabled={!projectId.id}>
                <FileAddTwoTone style={{ padding: '10px' }} disabled={!projectId.id}/> Change Request</Button>
            </Col>

          </Row>

          <Row gutter={24}>
            <Col lg={4} md={8} sm={4} xs={4}></Col>
            <Col lg={16} md={8} sm={14} xs={14}>
              <Table className="gx-table-responsive" loading={loading} columns={tasksListCols} dataSource={userTaskList} size="small"
              bordered={false} key={'id'} />
            </Col>
            <Col lg={4} md={8} sm={2} xs={2}></Col>
          </Row>

        </Col>
      </Row>

      <Modal closable={false} centered keyboard={false} maskClosable={false} title={"New Task"}
        visible={isModalOpen} okText={"New Task"} onOk={() => { taskForm.submit(); }}
        onCancel={() => { taskForm.resetFields(); setIsModalOpen(false); }}
        wrapClassName={`${"modal-ticket-create"}`}>
        <Row gutter={24} >
          <Form
            form={taskForm}
            name="taskForm"
            onFinish={onTaskFormFinish}
            layout="inline"
            align="center"
          >
            <Row gutter={24}>
            <Col lg={24} md={24} sm={24} xs={24} >
            <div className="gx-form-row0">
            <b>{projectId.name}</b>
            </div>
            </Col>
              <Col lg={24} md={24} sm={24} xs={24} >
                <div className="gx-form-row0">
                  <h6 align="left">Name</h6>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Enter the name',
                      },
                    ]}
                  >
                    <Input placeholder="Task Name"></Input>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={24} md={24} sm={24} xs={24} >
                <div className="gx-form-row0">
                  <h6 align="left">Description</h6>
                  <Form.Item
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: 'Enter the description',
                      },
                    ]}
                  >
                    <TextArea placeholder="Task description" onChange={e => handleDescription(e)} rows={4} value={description} maxLength={255} />
                    
                  </Form.Item>
                  <p style={{ textAlign: 'right', marginTop: '10px', marginBottom: '0px' }}> {`${textAreaCount} / 255`} </p>
                </div>
              </Col>
            </Row>
          </Form>
        </Row>

      </Modal>

    </Auxiliary>
  );
};
export default CustomerHome;