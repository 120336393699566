import React, { useEffect, useState } from "react";
import { Input, Button, Cascader, Col, Row, DatePicker, message, Popconfirm, TimePicker, Modal } from 'antd';
import { MinusCircleOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import { API_URL } from 'constants/APIConfig';
import moment from 'moment';
import axios from 'axios';
import Auxiliary from "util/Auxiliary";

import Widget from "../../../../components/Widget/index";
import { useHistory } from "react-router-dom";
import { Collapse } from 'antd';
import { Select } from 'antd';

const { TextArea } = Input;

const TSApp = () => {
  const history = useHistory();
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');
  const initialState = []

  const [state, setState] = useState(initialState);
  const [projectTaskList, setProjectTaskList] = useState([]);
  const [inputList, setInputList] = useState([]);
  const [disableFlag, setDisableFlag] = useState(false);
  const [weekNumber, setWeekNumber] = useState(moment().week());
  const [yearNumber, setYearNumber] = useState(moment().weekYear());
  const [comments, setComments] = useState("");

  const [visibleSave, setVisibleSave] = React.useState(false);
  const [visibleSubmit, setVisibleSubmit] = React.useState(false);
  const [confirmLoadingSave, setConfirmLoadingSave] = React.useState(false);
  const [confirmLoadingSubmit, setConfirmLoadingSubmit] = React.useState(false);
  const [textAreaCount, setTextAreaCount] = React.useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { confirm } = Modal;

  let data = [];

  const Panel = Collapse.Panel;

  function callback(key) {
    console.log(key);
  }

  const showConfirm = () => {
    confirm({
      title: 'Please rotate your mobile horizontaly and submit your timesheet.',
      icon: <ExclamationCircleOutlined />,
      cancelButtonProps:{ style: { display: 'none' } },
      onOk() {
        console.log('OK');
      },
    });
  };

  const showPopconfirmSave = () => {
    setVisibleSave(true);
  }

  const showPopconfirmSubmit = () => {
    setVisibleSubmit(true);
  }

  const handleOkSave = () => {
    setConfirmLoadingSave(true);
    setTimeout(() => {
      onFinish();
      setVisibleSave(false);
      setConfirmLoadingSave(false);
    }, 2000);
  }

  const handleOkSubmit = () => {
    setConfirmLoadingSubmit(true);
    let w = moment().week();
    let d = moment().day();
    if (w == weekNumber && d < 3) {
      setTimeout(() => {
        setVisibleSubmit(false);
        setIsModalOpen(true)
        setConfirmLoadingSubmit(false);
      }, 500);

    } else {
      setTimeout(() => {
        submitTimesheet();
        setVisibleSubmit(false);
        setConfirmLoadingSubmit(false);
      }, 2000);
    }
  }

  const handleCancelSave = () => {
    console.log('Clicked cancel button');
    setVisibleSave(false);
  }

  const handleCancelSubmit = () => {
    setVisibleSubmit(false);
  }

  const onChangeWeek = (date, dateString) => {
    if (date != null && dateString != null) {

      let weekDt = dateString.split('-');
      if (weekDt[0] != 0 && weekDt[1].match(/\d+/)[0] != 0) {
        let weekNumberTemp = Number(weekDt[1].match(/\d+/)[0]);
        let yearNumberTemp = Number(weekDt[0]);
        setWeekNumber(weekNumberTemp);
        setYearNumber(yearNumberTemp);
        setWeekDays(yearNumberTemp,weekNumberTemp,"onWeekChange")

        getWeeklyTS(weekDt[0], weekDt[1].match(/\d+/)[0]);
      }
    }
  }

  const setWeekDays = (yearNumberTemp,weekNumberTemp,isFrom)=>{
    let teampTimesheet = [];
    [ ...Array(7) ].map((d, i ) =>{
      let obj = {
        day: isFrom && isFrom === "onWeekChange" ? moment().weekYear(yearNumberTemp).week(weekNumberTemp).day(i).format('MMM D') : moment().weekYear(moment().weekYear()).week(moment().week()).day(i).format('MMM D') ,
        time:"",
        listOfTasks:[],
      }
      teampTimesheet.push(obj);
    })
    setState(teampTimesheet);
  }

  const handleComments = (e) => {
    setComments(e.target.value);
    setTextAreaCount(e.target.value.length);
  }

  const minsToHrsTime = (val) => {
    let m = val % 60;
    let h = (val - m) / 60;
    h = h < 10 ? "0" + h : h;
    return h;
  }

  const checkForDuplicateTasks = () => {
    return new Set(inputList.map(item => item[`task`])).size !== inputList.length
  }

  const exceedHrsDay = () => {
    let exceedHrs = false;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total7 = 0;

    inputList.map((x, i) => {
      total1 = total1 + strTimeToMins(x.day1)
      total2 = total2 + strTimeToMins(x.day2)
      total3 = total3 + strTimeToMins(x.day3)
      total4 = total4 + strTimeToMins(x.day4)
      total5 = total5 + strTimeToMins(x.day5)
      total6 = total6 + strTimeToMins(x.day6)
      total7 = total7 + strTimeToMins(x.day7)
    })

    if (minsToHrsTime(total1) > 12 || minsToHrsTime(total2) > 12 || minsToHrsTime(total3) > 12 || minsToHrsTime(total4) > 12 || minsToHrsTime(total5) > 12 || minsToHrsTime(total6) > 12 || minsToHrsTime(total7) > 12)
      exceedHrs = true;

    return exceedHrs;
  }

  const minsToStrTime = (val) => {
    let m = val % 60;
    let h = (val - m) / 60;

    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;

    return `${h}:${m}`;
  }

  const strTimeToMins = (strTime) => {
    let [h, m] = strTime.split(":");
    return Number(h) * 60 + Number(m);
  }

  const validateTasks = () => {
    let leaves = undefined;
    let publicHoliday = undefined
    inputList.map((tsheet, index) => {
      if (tsheet.project == 502) {
        publicHoliday = tsheet;
      }
      if (tsheet.project == 503) {
        leaves = tsheet;
      }
    });
    if (publicHoliday && leaves) {
      for (let i = 1; i <= 7; i++) {
        let lday = parseInt((leaves['day' + i]).replace(':', ''));
        let pday = parseInt((publicHoliday['day' + i]).replace(':', ''));
        if (lday != 0 && pday != 0) {
          message.error(`Leave and Public Holiday for the same day are not allowed`);
          return true;
        }
      }
    }
    return false;
  }

  const submitTimesheet = () => {

    let taskdata1 = [];
    let taskTotal = 0;
    let timesheet = [];
    let totalZero = false;
    let emptyTaskNameFlag = false;
    inputList.map((tsheet, index) => {
      taskTotal = taskTotal + (strTimeToMins(tsheet.day1) + strTimeToMins(tsheet.day2) + strTimeToMins(tsheet.day3) + strTimeToMins(tsheet.day4) + strTimeToMins(tsheet.day5) + strTimeToMins(tsheet.day6) + strTimeToMins(tsheet.day7))
      if (taskTotal == 0) {
        totalZero = true;
        timesheet = { ...tsheet };
        if (tsheet.task == '') {
          emptyTaskNameFlag = true;
        }
      }
    })

    if (emptyTaskNameFlag) {
      message.error(`Empty task name not allowed`);
      return;
    }
    if (validateTasks()) {
      return;
    }

    if (!totalZero && !exceedHrsDay() && !checkForDuplicateTasks() && minsToHrsTime(taskTotal) >= 40) {
      inputList.map((tsheet, index) => {
        taskdata1.push(
          {
            "isDefaultTask": false,
            "projectId": tsheet.project,
            "taskId": tsheet.task,
            "id": tsheet.timesheetId,
            "timelog": [
              {
                "dayofweek": 1,
                "hours": tsheet.day1,
                "id": tsheet.tlog_day1,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(0).format("YYYY-MM-DD"),
              },
              {
                "dayofweek": 2,
                "hours": tsheet.day2,
                "id": tsheet.tlog_day2,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(1).format("YYYY-MM-DD"),
              },
              {
                "dayofweek": 3,
                "hours": tsheet.day3,
                "id": tsheet.tlog_day3,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(2).format("YYYY-MM-DD"),
              },
              {
                "dayofweek": 4,
                "hours": tsheet.day4,
                "id": tsheet.tlog_day4,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(3).format("YYYY-MM-DD"),
              },
              {
                "dayofweek": 5,
                "hours": tsheet.day5,
                "id": tsheet.tlog_day5,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(4).format("YYYY-MM-DD"),
              },
              {
                "dayofweek": 6,
                "hours": tsheet.day6,
                "id": tsheet.tlog_day6,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(5).format("YYYY-MM-DD"),
              },
              {
                "dayofweek": 7,
                "hours": tsheet.day7,
                "id": tsheet.tlog_day7,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(6).format("YYYY-MM-DD"),
              }
            ]
          })

      })
      if (!totalZero && taskdata1.length > 0) {
        axios.post(API_URL + '/api/timesheets/v2/createOrUpdate', {
          "year": yearNumber,
          "weekNumber": weekNumber,
          "userId": userDetails.id,
          "reportingManagerId": userDetails.reportingManagerNm,
          "taskData": taskdata1,
        },
          {
            headers: {
              'x-access-token': `${token}`,
              'Access-Control-Allow-Origin': '*'
            }
          })
          .then(response => {
            if (!totalZero && taskdata1.length > 0 && minsToHrsTime(taskTotal) >= 40) {
              setTimeout(() => {
                axios.post(API_URL + '/api/timesheets/submitTimesheet', {
                  "year": yearNumber,
                  "weekNumber": weekNumber,
                  "userId": userDetails.id,
                  "timesheetComment": comments,
                },
                  {
                    headers: {
                      'x-access-token': `${token}`,
                      'Access-Control-Allow-Origin': '*'
                    }
                  })
                  .then(response => {
                    setDisableFlag(true)
                    message.success("Timesheet Saved & Submitted Successfully");
                    history.push('/timesheets/submit');
                  })
                  .catch(error => {
                    if (error.response) {
                      message.error(error.response.data.error)
                    } else {
                      message.error("Error In submitting timesheet", error)
                    }
                  });
              }, 200);
            }
          })
          .catch(error => {
            if (error.response) {
              message.error(error.response.data.error)
            } else {
              message.error("Error In saving timesheet", error)
            }
          });

      } else {
        message.error("Please add task line items to the timesheet");
      }
    } else {
      if (totalZero) {
        projectTaskList.map((projectItem, index) => {
          projectItem.children.map((taskItem, index) => {
            if (taskItem.value == `${timesheet.task}`)
              message.error(`Task hours not entered for the task ${taskItem.label}`);
          })
        })
      }
      if (minsToHrsTime(taskTotal) < 40)
        message.error("Please submit timesheet with minimum of 40 hours");

      if (exceedHrsDay())
        message.error("Please don't log more than 12 hours/day");

      if (checkForDuplicateTasks())
        message.error("Please don't log hours for duplicate tasks");
    }
  }

  const onFinish = () => {
    let taskdata1 = [];
    let taskTotal = 0;
    let timesheet = [];
    let totalZero = false;

    let emptyTaskNameFlag = false;
    inputList.map((tsheet, index) => {
      taskTotal = taskTotal + (strTimeToMins(tsheet.day1) + strTimeToMins(tsheet.day2) + strTimeToMins(tsheet.day3) + strTimeToMins(tsheet.day4) + strTimeToMins(tsheet.day5) + strTimeToMins(tsheet.day6) + strTimeToMins(tsheet.day7))
      if (taskTotal == 0) {
        totalZero = true;
        timesheet = { ...tsheet };
        if (tsheet.task == '') {
          emptyTaskNameFlag = true;
        }
      }
    })

    if (emptyTaskNameFlag) {
      message.error(`Empty task name not allowed`);
      return;
    }

    if (validateTasks()) {
      return;
    }

    if (!totalZero && !exceedHrsDay() && !checkForDuplicateTasks()) {
      inputList.map((tsheet, index) => (
        taskdata1.push(
          {
            "isDefaultTask": false,
            "projectId": tsheet.project,
            "taskId": tsheet.task,
            "id": tsheet.timesheetId,
            "timelog": [
              {
                "dayofweek": 1,
                "hours": tsheet.day1,
                "id": tsheet.tlog_day1,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(0).format("YYYY-MM-DD"),
              },
              {
                "dayofweek": 2,
                "hours": tsheet.day2,
                "id": tsheet.tlog_day2,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(1).format("YYYY-MM-DD"),
              },
              {
                "dayofweek": 3,
                "hours": tsheet.day3,
                "id": tsheet.tlog_day3,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(2).format("YYYY-MM-DD"),
              },
              {
                "dayofweek": 4,
                "hours": tsheet.day4,
                "id": tsheet.tlog_day4,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(3).format("YYYY-MM-DD"),
              },
              {
                "dayofweek": 5,
                "hours": tsheet.day5,
                "id": tsheet.tlog_day5,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(4).format("YYYY-MM-DD"),
              },
              {
                "dayofweek": 6,
                "hours": tsheet.day6,
                "id": tsheet.tlog_day6,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(5).format("YYYY-MM-DD"),
              },
              {
                "dayofweek": 7,
                "hours": tsheet.day7,
                "id": tsheet.tlog_day7,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(6).format("YYYY-MM-DD"),
              }
            ]
          })
      ))
      if (!totalZero && taskdata1.length > 0 && taskTotal > 0) {
        axios.post(API_URL + '/api/timesheets/v2/createOrUpdate', {
          "year": yearNumber,
          "weekNumber": weekNumber,
          "userId": userDetails.id,
          "reportingManagerId": userDetails.reportingManagerNm,
          "timesheetComment": comments,
          "taskData": taskdata1,
        },
          {
            headers: {
              'x-access-token': `${token}`,
              'Access-Control-Allow-Origin': '*'
            }
          })
          .then(response => {
            message.success("Timesheet Saved Successfully");
            history.push('/timesheets/submit')
          })
          .catch(error => {
            if (error.response) {
              message.error(error.response.data.error)
            } else {
              message.error("Error In Saving timesheet", error)
            }
          });
      } else {
        message.error("Please add task line items to the timesheet");
      }
    } else {
      if (totalZero) {
        projectTaskList.map((projectItem, index) => {
          projectItem.children.map((taskItem, index) => {
            if (taskItem.value == `${timesheet.task}`)
              message.error(`Task hours not entered for the ${taskItem.label}`);
          })
        })
      }

      if (exceedHrsDay())
        message.error("Please don't log more than 12 hours/day");

      if (checkForDuplicateTasks())
        message.error("Please don't log hours for duplicate tasks");
    }
  }

  const handleInputChange = (time, timeString, e, index, title) => {
    const list = [...inputList];
    if (title === "day1" || title === "day2" || title === "day3" || title === "day4" || title === "day5" || title === "day6" || title === "day7") {
      list[index][title] = time ? time.format("HH:mm") : null;
    } else if (title == "project") {
      list[index]['project'] = e[0];
      list[index]['task'] = e[1];
    }
    setInputList(list);
  }

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  }

  const removetasks = (taskInd,list)=>{
   let removedTasksData =  list.filter((value, ind)=> ind !== taskInd);
   return removedTasksData;
  }

  const handleRemoveTask = (dayInd, taskInd) => {
    setState(state.map((day, index)=>{
      if(index == dayInd){
        let list = [...day.listOfTasks]
        return {
          ...day,
          listOfTasks : removetasks(taskInd,list)
        }
      }else{
        return day
      }
    }
    ))
  }

  const handleAddClick = () => {
    setInputList([...inputList, { project: "", task: "", day1: "00:00", day2: "00:00", day3: "00:00", day4: "00:00", day5: "00:00", day6: "00:00", day7: "00:00" }]);
  }

  const handleAddTask = (dayIndex) => {

    setState(state.map((day, index)=>{
      if(index === dayIndex){
        let obj = {
          project: "",
          task: "",
          hours: "00:00" ,
        }
        return {
          ...day,
          listOfTasks : [...day.listOfTasks, obj ]
        }
      }else{
        return day
      }
    }
    ))
  }

  const getWeeklyTS = async (year, week) => {
    const url = API_URL + `/api/timesheets/v2/getUserTimesheet?userId=${userDetails.id}&year=${year}&weekNumber=${week}`;
    try {
      const res = await axios.get(url,
        {
          headers: {
            'x-access-token': `${token}`,
            'Access-Control-Allow-Origin': '*'
          }
        });
      if (res.status === 200) {
        if (Object.keys(res.data.data).length > 0) {
          let tsheets = [];
          if (res.data.data.timesheet.length) {
            setDisableFlag(res.data.data.timesheet[0].timesheetStatus >= 0 ? true : false)
          } else {
            setDisableFlag(false)
          }

          res.data.data.timesheet.map((tsheet, index) => (
            setComments(tsheet.timesheetComment),
            tsheet.projects.map((projectItem, index) => (
              projectItem.tasks.map((taskItem, index) => (
                tsheets = [...tsheets, {
                  "project": projectItem.project && projectItem.project.id,
                  "task": taskItem.task && taskItem.task.id,
                  "timesheetId": taskItem.timesheetId,
                  "taskname": taskItem.task && taskItem.task.taskNameTx,
                  "day1": taskItem.timelog[0].hours,
                  "tlog_day1": taskItem.timelog[0].timelogId,
                  "day2": taskItem.timelog[1].hours,
                  "tlog_day2": taskItem.timelog[1].timelogId,
                  "day3": taskItem.timelog[2].hours,
                  "tlog_day3": taskItem.timelog[2].timelogId,
                  "day4": taskItem.timelog[3].hours,
                  "tlog_day4": taskItem.timelog[3].timelogId,
                  "day5": taskItem.timelog[4].hours,
                  "tlog_day5": taskItem.timelog[4].timelogId,
                  "day6": taskItem.timelog[5].hours,
                  "tlog_day6": taskItem.timelog[5].timelogId,
                  "day7": taskItem.timelog[6].hours,
                  "tlog_day7": taskItem.timelog[6].timelogId,
                }]
              ))
            ))
          ))
          tsheets.sort((a, b) => {
            return a.timesheetId - b.timesheetId;
          })
          setInputList(tsheets);
          if (tsheets.length == 0) {
            setComments("");
          }
        } else {
          setDisableFlag(false)
        }
      } else {
        setComments("");
        return {};
      }
    } catch (ex) {
      return {}
    }
  }

  const getProjectList = async () => {
    const url = API_URL + '/api/project/v2/get/assigned/projects/' + userDetails.id;
    try {
      const res = await axios.get(url,
        {
          headers: {
            'x-access-token': `${token}`,
            'Access-Control-Allow-Origin': '*'
          }
        });
      if (res.status === 200) {
        if (Object.keys(res.data.data).length > 0) {
          let assignedprojects = [];
          res.data.data.map((item, index) => (
            assignedprojects = item.projects
          ))

          assignedprojects.map((project, index) => (
            getTaskLists(project)
          ))
        }
      } else return {};
    } catch (ex) {
      console.log(`${ex.message}:get timesheet`);
      return {}
    }
  }

  const getTaskLists = async (project) => {
    const url = API_URL + `/api/task/getUsersTasksList?userId=${userDetails.id}&projectId=${project.id}&limit=100&offset=0`;
    try {
      const res = await axios.get(url,
        {
          headers: {
            'x-access-token': `${token}`,
            'Access-Control-Allow-Origin': '*'
          }
        });
      if (res.status === 200) {
        if (Object.keys(res.data.data).length > 0) {
          let data1 = [];
          let childTask = [];
          res.data.data.map((task, index) => {
            if(task.taskStatusNm !== 5) {
              childTask.push({
                disabled: task.taskStatusNm == 5 ? true : false,
                value: task.id,
                label: task.taskNameTx,
              })
            }
          })
          data1 = {
            ...data1,
            value: project.id,
            label: project.name,
            children: childTask,
          };
          if(childTask.length) {
            data = [...data, data1];
            setProjectTaskList(data);
          }
        }
      }
    } catch (ex) {
      return {}
    }
  }

  /* Accordian List Start */
  const onChange = (value, dayIndex , taskInd,project) => {
  };
  const onSearch = (value) => {
    console.log('search:', value);
  };

  const PanelCueCard = (dayInd, taskInd) => {
    return (
      <div className="TsCueCard">
        <p onClick={() => handleRemoveTask(dayInd, taskInd)}> <i class="icon icon-trash"></i></p>
        <div className="cueCardSelect">
          <div>
            <Select
              showSearch
              placeholder="Select a project"
              optionFilterProp="Project Title"
              onChange={(e)=>onChange(e, dayInd , taskInd,"project")}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                {
                  value: 'Project Name1',
                  label: 'Project Name1',
                },
                {
                  value: 'Project Name2',
                  label: 'Project Name2',
                },
                {
                  value: 'Project Name3',
                  label: 'Project Name3',
                },
              ]}
            />
          </div>
          <div>
            <Select
              showSearch
              placeholder="Select a Task"
              optionFilterProp="Task Title"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                {
                  value: 'Task Name1',
                  label: 'Task Name1',
                },
                {
                  value: 'Task Name2',
                  label: 'Task Name2',
                },
                {
                  value: 'Task Name3',
                  label: 'Task Name3',
                },
              ]}
            />
          </div>

          <div>
            <Select
              showSearch
              placeholder="Select Time"
              optionFilterProp="Time"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                {
                  value: 'Time1',
                  label: 'Time1',
                },
                {
                  value: 'Time2',
                  label: 'Time2',
                },
                {
                  value: 'Time3',
                  label: 'Time3',
                },
              ]}
            />
          </div>
        </div>
      </div>
    )
  }

  const panelHeaderContent = (day, date, hoursFilled) => {
    return (
      <div className="gx-d-flex justifyContent-spaceBetween">
        <p><span>{day}</span> <span>{date}</span></p>
        <p>{hoursFilled}</p>
      </div>
    )
  }

  useEffect(() => {
    var myScreenOrientation = window.screen.orientation;
    if(myScreenOrientation.type === "portrait-primary") showConfirm()
    getProjectList();
    setWeekDays()
    getWeeklyTS(moment().year(), moment().week());
  }, [])

  return (
    <Auxiliary>
      <Widget>
        <div className="bS-newTimesheet">

          <Row className="dateRangeBlock m_0">
            <Col xl={8} lg={8} md={8} sm={24} xs={24} className="timesheetHeading1 gx-pl-0 mCenter"><h2 className="heading-grey">Enter Your Timesheet</h2></Col>
            <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gx-text-center displayDate">
              <h4>
                {
                  moment().weekYear(yearNumber).week(weekNumber).startOf('week').format('MMM D, YYYY') + " - " + moment().weekYear(yearNumber).week(weekNumber).endOf('week').format('MMM D, YYYY')
                }
              </h4></Col>
            <Col xl={8} lg={8} md={8} sm={24} xs={24} className="flex_style gx-pr-0 mCenter displayCalandar">
              <DatePicker
                picker="week"
                inputReadOnly={true}
                onChange={onChangeWeek}
                defaultValue={moment()}
                disabledDate={current => {
                  return current > moment().add(0, 'weeks')
                }}
                bordered={true}
              />
            </Col>
          </Row>

          <div className="timesheetBlock">
            <Row className="timesheetHeader m_0">
              <Col xl={8} lg={8} md={4} sm={4} xs={4} className="timesheetHeading upperCaseStyle timeSheetMiddle borderRight">projects/Tasks</Col>
              <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center weekdays_bg ">
                <p className="weekStyle">S</p>
                <p className="monthDateStyle">
                    {moment().weekYear(yearNumber).week(weekNumber).day(0).format('MMM D')}
                </p>

              </Col>
              <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
                <p className="weekStyle">M</p>
                <p className="monthDateStyle">
                    {moment().weekYear(yearNumber).week(weekNumber).day(1).format('MMM D')}
                </p>
              </Col>
              <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
                <p className="weekStyle">T</p>
                <p className="monthDateStyle">
                    {moment().weekYear(yearNumber).week(weekNumber).day(2).format('MMM D')}
                </p>
              </Col>
              <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
                <p className="weekStyle">W</p>
                <p className="monthDateStyle">
                    {moment().weekYear(yearNumber).week(weekNumber).day(3).format('MMM D')}
                </p>
              </Col>
              <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
                <p className="weekStyle">T</p>
                <p className="monthDateStyle">
                    {moment().weekYear(yearNumber).week(weekNumber).day(4).format('MMM D')}
                </p>
              </Col>
              <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
                <p className="weekStyle">F</p>
                <p className="monthDateStyle">
                    {moment().weekYear(yearNumber).week(weekNumber).day(5).format('MMM D')}
                </p>
              </Col>
              <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center weekdays_bg ">
                <p className="weekStyle">S</p>
                <p className="monthDateStyle">
                    {moment().weekYear(yearNumber).week(weekNumber).day(6).format('MMM D')}
                </p>
              </Col>
              <Col xl={2} lg={2} md={2} sm={2} xs={2} className="total_data gx-text-center timeSheetMiddle">
                <span className="weekStyle">Total</span>
              </Col>
            </Row>
          </div>
          <div className="App timesheetBody">
            {inputList.map((x, i) => {
              return (
                <div className="box" key={i}>
                  <Row className="m_0 spacing_bottom timesheetBodyBlock border_bottom">
                    <Col xl={8} lg={8} md={4} sm={4} xs={4} className="borderRight">
                      <Cascader
                        options={projectTaskList}
                        onChange={e => handleInputChange(null, null, e, i, "project")}
                        placeholder="Please select"
                        value={[x.project, x.task]}
                        disabled={disableFlag}
                      />
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells weekdays_bg timepicker">
                      <TimePicker
                        format="HH:mm"
                        disabled={disableFlag}
                        name="day1"
                        suffixIcon={null}
                        showNow={false}
                        inputReadOnly={true}
                        placeholder="00:00"
                        minuteStep={15}
                        value={moment(x.day1, 'HH:mm')}
                        //defaultValue={moment(x.day1, 'HH:mm')}
                        //onChange={(time, timeString) => handleInputChange(time, timeString, null, i, "day1")}
                        onSelect={(time) => handleInputChange(time, null, null, i, "day1")}
                        disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                      />
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells timepicker">
                      <TimePicker
                        format="HH:mm"
                        disabled={disableFlag}
                        name="day2"
                        suffixIcon={null}
                        showNow={false}
                        inputReadOnly={true}
                        placeholder="00:00"
                        minuteStep={15}
                        value={moment(x.day2, 'HH:mm')}
                        //defaultValue={moment(x.day2, 'HH:mm')}
                        onSelect={(time) => handleInputChange(time, null, null, i, "day2")}
                        //onChange={(time, timeString) => handleInputChange(time, timeString, null, i, "day2")}
                        disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                      />
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells timepicker">
                      <TimePicker
                        format="HH:mm"
                        disabled={disableFlag}
                        name="day3"
                        suffixIcon={null}
                        showNow={false}
                        inputReadOnly={true}
                        placeholder="00:00"
                        minuteStep={15}
                        value={moment(x.day3, 'HH:mm')}
                        // defaultValue={moment(x.day3, 'HH:mm')}
                        onSelect={(time) => handleInputChange(time, null, null, i, "day3")}
                        // onChange={(time, timeString) => handleInputChange(time, timeString, null, i, "day3")}
                        disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                      />
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells timepicker">
                      <TimePicker
                        format="HH:mm"
                        disabled={disableFlag}
                        name="day4"
                        suffixIcon={null}
                        showNow={false}
                        inputReadOnly={true}
                        placeholder="00:00"
                        minuteStep={15}
                        value={moment(x.day4, 'HH:mm')}
                        //defaultValue={moment(x.day4, 'HH:mm')}
                        onSelect={(time) => handleInputChange(time, null, null, i, "day4")}
                        //onChange={(time, timeString) => handleInputChange(time, timeString, null, i, "day4")}
                        disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                      />
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells timepicker">
                      <TimePicker
                        format="HH:mm"
                        disabled={disableFlag}
                        name="day5"
                        suffixIcon={null}
                        showNow={false}
                        inputReadOnly={true}
                        placeholder="00:00"
                        minuteStep={15}
                        value={moment(x.day5, 'HH:mm')}
                        // defaultValue={moment(x.day5, 'HH:mm')}
                        onSelect={(time) => handleInputChange(time, null, null, i, "day5")}
                        // onChange={(time, timeString) => handleInputChange(time, timeString, null, i, "day5")}
                        disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                      />
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells timepicker">
                      <TimePicker
                        format="HH:mm"
                        disabled={disableFlag}
                        name="day6"
                        suffixIcon={null}
                        showNow={false}
                        inputReadOnly={true}
                        placeholder="00:00"
                        minuteStep={15}
                        value={moment(x.day6, 'HH:mm')}
                        //defaultValue={moment(x.day6, 'HH:mm')}
                        onSelect={(time) => handleInputChange(time, null, null, i, "day6")}
                        //onChange={(time, timeString) => handleInputChange(time, timeString, null, i, "day6")}
                        disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                      />
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells weekdays_bg timepicker">
                      <TimePicker
                        format="HH:mm"
                        disabled={disableFlag}
                        name="day7"
                        suffixIcon={null}
                        showNow={false}
                        inputReadOnly={true}
                        placeholder="00:00"
                        minuteStep={15}
                        value={moment(x.day7, 'HH:mm')}
                        //defaultValue={moment(x.day7, 'HH:mm')}
                        onSelect={(time) => handleInputChange(time, null, null, i, "day7")}
                        // onChange={(time, timeString) => handleInputChange(time, timeString, null, i, "day7")}
                        disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                      />
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="total_value timeSheetMiddle">
                      <span>{
                        // (moment(0 + ':' + 0, 'HH:mm').add(strTimeToMins(x.day1) + strTimeToMins(x.day2) + strTimeToMins(x.day3) + strTimeToMins(x.day4) + strTimeToMins(x.day5) + strTimeToMins(x.day6) + strTimeToMins(x.day7), 'm')).format("HH:mm")
                        minsToStrTime(strTimeToMins(x.day1) + strTimeToMins(x.day2) + strTimeToMins(x.day3) + strTimeToMins(x.day4) + strTimeToMins(x.day5) + strTimeToMins(x.day6) + strTimeToMins(x.day7))
                      }</span>
                      {(() => {
                        if (!disableFlag)
                          return (<MinusCircleOutlined onClick={() => handleRemoveClick(i)} />);
                      })()}
                    </Col>
                  </Row>
                </div>
              );
            })}
            <Row className="">
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                {(() => {
                  if (!disableFlag) {
                    return (<Button type="text" disabled={disableFlag} onClick={handleAddClick} className="gx-mt-3 addBtn"><i className="fa fa-plus" aria-hidden="true"></i>Tasks</Button>)
                  }
                })()}
              </Col>
            </Row>
          </div>
          <div className="timesheetBlock">
            <Row className="timesheetHeader m_0 timeSheetMiddle totalHoursSec">
              <Col xl={8} lg={8} md={8} sm={8} xs={8} className="timesheetHeading upperCaseStyle timeSheetMiddle borderRight font600">Total Hours</Col>
              <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
                <p>
                  {(() => {
                    let total = 0;
                    inputList.map((x, i) => {
                      total = total + strTimeToMins(x.day1)
                    })
                    return minsToStrTime(total);
                  })()} </p>
              </Col>
              <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
                <p>
                  {(() => {
                    let total = 0;
                    inputList.map((x, i) => {
                      total = total + strTimeToMins(x.day2)
                    })
                    return minsToStrTime(total);
                  })()} </p>
              </Col>
              <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
                <p>
                  {(() => {
                    let total = 0;
                    inputList.map((x, i) => {
                      total = total + strTimeToMins(x.day3)
                    })
                    return minsToStrTime(total);
                  })()}
                </p>
              </Col>
              <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
                <p>
                  {(() => {
                    let total = 0;
                    inputList.map((x, i) => {
                      total = total + strTimeToMins(x.day4)
                    })
                    return minsToStrTime(total);
                  })()} </p>
              </Col>
              <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
                <p>
                  {(() => {
                    let total = 0;
                    inputList.map((x, i) => {
                      total = total + strTimeToMins(x.day5)
                    })
                    return minsToStrTime(total);
                  })()} </p>
              </Col>
              <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
                <p>
                  {(() => {
                    let total = 0;
                    inputList.map((x, i) => {
                      total = total + strTimeToMins(x.day6)
                    })
                    return minsToStrTime(total);
                  })()} </p>
              </Col>
              <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
                <p>
                  {(() => {
                    let total = 0;
                    inputList.map((x, i) => {
                      total = total + strTimeToMins(x.day7)
                    })
                    return minsToStrTime(total);
                  })()} </p>
              </Col>
              <Col xl={2} lg={2} md={2} sm={2} xs={2} className="totalcountMain gx-text-center">
                <p>
                  {(() => {
                    let total = 0;
                    inputList.map((x, i) => {
                      total = total + (strTimeToMins(x.day1) + strTimeToMins(x.day2) + strTimeToMins(x.day3) + strTimeToMins(x.day4) + strTimeToMins(x.day5) + strTimeToMins(x.day6) + strTimeToMins(x.day7))
                    })
                    return minsToStrTime(total);
                  })()} </p>
              </Col>
            </Row>
          </div>

          <Row className="m_0">
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-p-0">
              <p className="gx-mb-1 gx-mt-1">Comments:</p>
              <TextArea placeholder="Please enter the comments" disabled={disableFlag} onChange={e => handleComments(e)} rows={4} value={comments} maxLength={255} />
              <p style={{ textAlign: 'right', marginTop: '10px', marginBottom: '0px' }}> {`${textAreaCount} / 255`} </p>
            </Col>
          </Row>

          <Row className="m_0">
            <Col xl={8} lg={8} md={8} sm={8} xs={8} className="gx-p-0">
              <Button type="primary" className="gx-mr-2" onClick={() => history.push('/timesheets/submit/')}>Back</Button>
            </Col>
            <Col xl={16} lg={16} md={16} sm={16} xs={16} className="gx-p-0 buttonGroup">
              {(() => {
                if (!disableFlag) {
                  return (<>

                    {inputList.length == 0 ?
                      <Button type="primary" disabled={true} className="submit_btn" onClick={showPopconfirmSave} htmlType="save">Save </Button>
                      : <Popconfirm
                        title="Are you sure to save timesheet? Once saved you will be able to edit."
                        placement="top"
                        visible={visibleSave}
                        onConfirm={handleOkSave}
                        okButtonProps={{ loading: confirmLoadingSave }}
                        onCancel={handleCancelSave}
                      >
                        <Button type="primary" disabled={disableFlag} className="submit_btn" onClick={showPopconfirmSave} htmlType="save">Save </Button>

                      </Popconfirm>
                    }
                    {inputList.length == 0 ?
                      <Button type="primary" disabled={true} className="save_btn" onClick={showPopconfirmSubmit} htmlType="submit">Submit</Button>
                      :
                      <Popconfirm
                        title="Are you sure to submit timesheet? Once submitted you will not be able to edit."
                        placement="top"
                        visible={visibleSubmit}
                        onConfirm={handleOkSubmit}
                        okButtonProps={{ loading: confirmLoadingSubmit }}
                        onCancel={handleCancelSubmit}
                      >
                        <Button type="primary" disabled={disableFlag} className="save_btn" onClick={showPopconfirmSubmit} htmlType="submit">Submit</Button>
                      </Popconfirm>
                    }
                  </>
                  )
                }
              })()}
            </Col>
          </Row>

          <Modal closable={false} centered title="Confirmation" visible={isModalOpen} onOk={() => { setIsModalOpen(false); submitTimesheet(); }}
            onCancel={() => { setIsModalOpen(false); }}
            wrapClassName={"modal-ticket-update"} keyboard={false} maskClosable={false}>
            <p>You are submitting the timesheet early for the current week <b>{moment().week()}</b>.</p>
            <center><h5>{
              moment().weekYear(yearNumber).week(weekNumber).startOf('week').format('MMM D, YYYY') + " - " + moment().weekYear(yearNumber).week(weekNumber).endOf('week').format('MMM D, YYYY')
            }</h5></center>
            Are you sure to submit the timesheet ?
          </Modal>
        </div>

        <div className="mS-newTimesheet">
          <Row className="dateRangeBlock m_0">
            <Col xl={8} lg={8} md={8} sm={24} xs={24} className="timesheetHeading1 gx-pl-0 mCenter"><h2 className="heading-grey">Enter Your Timesheet</h2></Col>
            <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gx-text-center">
              <h4>
                {
                  moment().weekYear(yearNumber).week(weekNumber).startOf('week').format('MMM D, YYYY') + " - " + moment().weekYear(yearNumber).week(weekNumber).endOf('week').format('MMM D, YYYY')
                }
              </h4></Col>
            <Col xl={8} lg={8} md={8} sm={24} xs={24} className="flex_style gx-pr-0 mCenter">
              <DatePicker
                picker="week"
                onChange={onChangeWeek}
                defaultValue={moment()}
                disabledDate={current => {
                  return current > moment().add(0, 'weeks')
                }}
                bordered={true}
              />
            </Col>
          </Row>

          <Row style={{ display: "block" }}>
            <Collapse accordion defaultActiveKey={['1']} onChange={callback}>
              {state.map((days, dayInd) => (
                <Panel header={panelHeaderContent(`Day + ${dayInd + 1}`, moment().weekYear(yearNumber).week(weekNumber).day(dayInd).format('MMM D'), "time")} key={dayInd+1}>
                  {
                    days.listOfTasks.length > 0 && days.listOfTasks.map((data, taskInd) => (
                      PanelCueCard(dayInd,taskInd)
                    )
                    )
                  }
                  {(() => {
                    if (!disableFlag) {
                      return (<Button type="text" disabled={disableFlag} onClick={()=>handleAddTask(dayInd)} className="gx-mt-3 addBtn"><i className="fa fa-plus" aria-hidden="true"></i>Tasks</Button>)
                    }
                  })()}
                </Panel>
              )
              )
              }
            </Collapse>
          </Row>

          <Row className="m_0">
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-p-0">
              <p className="gx-mb-1 gx-mt-1">Comments:</p>
              <TextArea placeholder="Please enter the comments" disabled={disableFlag} onChange={e => handleComments(e)} rows={4} value={comments} maxLength={255} />
              <p style={{ textAlign: 'right', marginTop: '10px', marginBottom: '0px' }}> {`${textAreaCount} / 255`} </p>
            </Col>
          </Row>

          <Row className="m_0">
            <Col xl={8} lg={8} md={8} sm={8} xs={8} className="gx-p-0">
              <Button type="primary" className="gx-mr-2" onClick={() => history.push('/timesheets/submit/')}>Back</Button>
            </Col>
            <Col xl={16} lg={16} md={16} sm={16} xs={16} className="gx-p-0 buttonGroup">
              {(() => {
                if (!disableFlag) {
                  return (<>

                    {inputList.length == 0 ?
                      <Button type="primary" disabled={true} className="submit_btn" onClick={showPopconfirmSave} htmlType="save">Save </Button>
                      : <Popconfirm
                        title="Are you sure to save timesheet? Once saved you will be able to edit."
                        placement="top"
                        visible={visibleSave}
                        onConfirm={handleOkSave}
                        okButtonProps={{ loading: confirmLoadingSave }}
                        onCancel={handleCancelSave}
                      >
                        <Button type="primary" disabled={disableFlag} className="submit_btn" onClick={showPopconfirmSave} htmlType="save">Save </Button>

                      </Popconfirm>
                    }
                    {inputList.length == 0 ?
                      <Button type="primary" disabled={true} className="save_btn" onClick={showPopconfirmSubmit} htmlType="submit">Submit</Button>
                      :
                      <Popconfirm
                        title="Are you sure to submit timesheet? Once submitted you will not be able to edit."
                        placement="top"
                        visible={visibleSubmit}
                        onConfirm={handleOkSubmit}
                        okButtonProps={{ loading: confirmLoadingSubmit }}
                        onCancel={handleCancelSubmit}
                      >
                        <Button type="primary" disabled={disableFlag} className="save_btn" onClick={showPopconfirmSubmit} htmlType="submit">Submit</Button>
                      </Popconfirm>
                    }
                  </>
                  )
                }
              })()}
            </Col>
          </Row>

          <Modal closable={false} centered title="Confirmation" visible={isModalOpen} onOk={() => { setIsModalOpen(false); submitTimesheet(); }}
            onCancel={() => { setIsModalOpen(false); }}
            wrapClassName={"modal-ticket-update"} keyboard={false} maskClosable={false}>
            <p>You are submitting the timesheet early for the current week <b>{moment().week()}</b>.</p>
            <center><h5>{
              moment().weekYear(yearNumber).week(weekNumber).startOf('week').format('MMM D, YYYY') + " - " + moment().weekYear(yearNumber).week(weekNumber).endOf('week').format('MMM D, YYYY')
            }</h5></center>
            Are you sure to submit the timesheet ?
          </Modal>
          
        </div>
      </Widget>
    </Auxiliary>
  )
}
export default TSApp;