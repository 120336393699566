import React, { useEffect, useState } from "react";
import { Input, Button, Cascader, Col, Row, DatePicker, message, TimePicker, Tooltip, Collapse, Tag, Steps, Avatar, Popover, Modal } from 'antd';
import { CheckOutlined, CloseOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { API_URL } from 'constants/APIConfig';
import moment from 'moment';
import 'moment/min/locales';
import axios from 'axios';
import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";
import { useHistory } from "react-router-dom";

const { TextArea } = Input;
const Panel = Collapse.Panel;
const { Step } = Steps;

const TSApp = (props) => {
  let history = useHistory();
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'));
  const token = sessionStorage.getItem('token');

  const [projectTaskList, setProjectTaskList] = useState([]);
  const [inputList, setInputList] = useState([]);
  const [deletedTaskData, setDeletedTaskData] = useState([]);
  const [disableFlag, setDisableFlag] = useState(false);
  const [saveButtonFlag, setSaveButtonFlag] = useState(false);
  const [weekNumber, setWeekNumber] = useState(props.match.params.week);
  const [yearNumber, setYearNumber] = useState(props.match.params.year);
  const [comments, setComments] = useState("");
  const [rmComments, setRMComments] = useState("");
  const [pmComments, setPMComments] = useState("");
  const [textAreaCount, setTextAreaCount] = React.useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stepData, setStepData] = useState(null);

  const minsToHrsTime = (val) => {
    let m = val % 60;
    let h = (val - m) / 60;

    h = h < 10 ? "0" + h : h;

    return h;
  }

  const strTimeToMins = (strTime) => {
    let [h, m] = strTime.split(":");
    return Number(h) * 60 + Number(m);
  }

  const minsToStrTime = (val) => {
    let m = val % 60;
    let h = (val - m) / 60;

    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;

    return `${h}:${m}`;
  }

  const checkForDuplicateTasks = () => {
    return new Set(inputList.map(item => item[`task`])).size !== inputList.length
  }

  const exceedHrsDay = () => {
    let exceedHrs = false;
    let total1 = 0;
    let total2 = 0;
    let total3 = 0;
    let total4 = 0;
    let total5 = 0;
    let total6 = 0;
    let total7 = 0;

    inputList.map((x, i) => {
      total1 = total1 + strTimeToMins(x.day1)
      total2 = total2 + strTimeToMins(x.day2)
      total3 = total3 + strTimeToMins(x.day3)
      total4 = total4 + strTimeToMins(x.day4)
      total5 = total5 + strTimeToMins(x.day5)
      total6 = total6 + strTimeToMins(x.day6)
      total7 = total7 + strTimeToMins(x.day7)
    })

    if (minsToHrsTime(total1) > 12 || minsToHrsTime(total2) > 12 || minsToHrsTime(total3) > 12 || minsToHrsTime(total4) > 12 || minsToHrsTime(total5) > 12 || minsToHrsTime(total6) > 12 || minsToHrsTime(total7) > 12)
      exceedHrs = true;

    return exceedHrs;
  }

  const onChangeWeek = (date, dateString) => {
    if (date != null && dateString != null) {
      let weekDt = dateString.split('-');
      if (weekDt[0] != 0 && weekDt[1].match(/\d+/)[0] != 0) {
        setWeekNumber(weekDt[1].match(/\d+/)[0]);
        setYearNumber(weekDt[0]);
        //getWeeklyTS(weekDt[0], weekDt[1].match(/\d+/)[0]);
        if (weekDt[1].match(/\d+/)[0] > moment().week()) {
          setTimeout(() => { setDisableFlag(true); }, 100);
        }
      }
    }
  }
  const handleComments = (e) => {
    setComments(e.target.value);
    setTextAreaCount(e.target.value.length);
  }

  const validateTasks = () => {
    let leaves = undefined;
    let publicHoliday = undefined
    inputList.map((tsheet, index) => {
      if (tsheet.project == 502) {
        publicHoliday = tsheet;
      }
      if (tsheet.project == 503) {
        leaves = tsheet;
      }
    });
    if (publicHoliday && leaves) {
      for (let i = 1; i <= 7; i++) {
        let lday = parseInt((leaves['day' + i]).replace(':', ''));
        let pday = parseInt((publicHoliday['day' + i]).replace(':', ''));
        if (lday != 0 && pday != 0) {
          message.error(`Leave and Public Holiday for the same day not allowed`);
          return true;
        }
      }
    }
    return false;
  }

  const validateSubmitTimesheet = () => {
    let w = moment().week();
    let d = moment().day();
    if( w == weekNumber && d <3) {
        setIsModalOpen(true)
    } else {
      submitTimesheet();
    }
  }

  const submitTimesheet = () => {

    let taskdata1 = [];
    let timesheet = [];
    let totalZero = false;
    let taskTotal = 0;
    let timesheetTotal = 0;
    let emptyTaskNameFlag = false;
    inputList.map((tsheet, index) => {
      taskTotal = (strTimeToMins(tsheet.day1) + strTimeToMins(tsheet.day2) + strTimeToMins(tsheet.day3) + strTimeToMins(tsheet.day4) + strTimeToMins(tsheet.day5) + strTimeToMins(tsheet.day6) + strTimeToMins(tsheet.day7))
      timesheetTotal = timesheetTotal + (strTimeToMins(tsheet.day1) + strTimeToMins(tsheet.day2) + strTimeToMins(tsheet.day3) + strTimeToMins(tsheet.day4) + strTimeToMins(tsheet.day5) + strTimeToMins(tsheet.day6) + strTimeToMins(tsheet.day7))
      if (taskTotal == 0) {
        totalZero = true;
        timesheet = { ...tsheet };
        if (tsheet.task == '') {
          emptyTaskNameFlag = true;
        }
      } else {
        taskdata1.push(
          {
            "isDefaultTask": false,
            "projectId": tsheet.project,
            "taskId": tsheet.task,
            "id": tsheet.timesheetId,
            "projectManagerAction": tsheet.projectManagerAction === 'Approved' ? tsheet.projectManagerAction : 'Pending',
            "timelog": [
              {
                "dayofweek": 1,
                "hours": tsheet.day1,
                "id": tsheet.tlog_day1,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(0).format("YYYY-MM-DD"),
                //"comments": ""
              },
              {
                "dayofweek": 2,
                "hours": tsheet.day2,
                "id": tsheet.tlog_day2,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(1).format("YYYY-MM-DD"),
                //"comments": ""
              },
              {
                "dayofweek": 3,
                "hours": tsheet.day3,
                "id": tsheet.tlog_day3,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(2).format("YYYY-MM-DD"),
                //"comments": ""
              },
              {
                "dayofweek": 4,
                "hours": tsheet.day4,
                "id": tsheet.tlog_day4,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(3).format("YYYY-MM-DD"),
                //"comments": ""
              },
              {
                "dayofweek": 5,
                "hours": tsheet.day5,
                "id": tsheet.tlog_day5,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(4).format("YYYY-MM-DD"),
                //"comments": ""
              },
              {
                "dayofweek": 6,
                "hours": tsheet.day6,
                "id": tsheet.tlog_day6,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(5).format("YYYY-MM-DD"),
                // "comments": ""
              },
              {
                "dayofweek": 7,
                "hours": tsheet.day7,
                "id": tsheet.tlog_day7,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(6).format("YYYY-MM-DD"),
                //"comments": ""
              }
            ]
          })
      }
    })

    if (emptyTaskNameFlag) {
      message.error(`Empty task name not allowed`);
      return;
    }
    if (validateTasks()) {
      return;
    }

    if (!totalZero && !exceedHrsDay() && !checkForDuplicateTasks() && taskdata1.length > 0 && minsToHrsTime(timesheetTotal) >= 40) {
      axios.post(API_URL + '/api/timesheets/v2/createOrUpdate', {
        "year": yearNumber,
        "weekNumber": weekNumber,
        "userId": userDetails.id,
        "reportingManagerId": userDetails.reportingManagerNm,
        "taskData": taskdata1,
        "deletedTaskData": deletedTaskData,
        "timesheetComment": comments,
      },
        {
          headers: {
            'x-access-token': `${token}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(response => {
          axios.post(API_URL + '/api/timesheets/submitTimesheet', {
            "year": yearNumber,
            "weekNumber": weekNumber,
            "userId": userDetails.id,
            "timesheetComment": comments,
          },
            {
              headers: {
                'x-access-token': `${token}`,
                'Access-Control-Allow-Origin': '*'
              }
            })
            .then(response => {
              setDisableFlag(true)
              message.success("Timesheet Saved & Sumitted Successfully");
              history.push('/timesheets/submit?' + new Date().getTime());
            })
            .catch(error => {
              if (error.response) {
                message.error(error.response.data.error)
              } else {
                message.error("Error In Sumitted timesheet", error)
              }
            })
        })
        .catch(error => {
          if (error.response) {
            message.error(error.response.data.error)
          } else {
            message.error("Error In Sumitted timesheet", error)
          }
        });
    } else {
      message.error("Please add task line items to the timesheet");
      if (totalZero) {
        projectTaskList.map((projectItem, index) => {
          projectItem.children.map((taskItem, index) => {
            if (taskItem.value == `${timesheet.task}`)
              message.error(`Task hours not entered for the task ${taskItem.label}`);
          })
        })
      }
      if (minsToHrsTime(timesheetTotal) < 40) {
        message.error("Please submit timesheet with minimum of 40 hours");
      }

      if (exceedHrsDay()) {
        message.error("Please don't log more than 12 hours/day");
      }

      if (checkForDuplicateTasks()) {
        message.error("Please don't log hours for duplicate tasks");
      }
    }
  }

  const onFinish = () => {
    let taskdata1 = [];

    let taskTotal = 0;
    let timesheet = [];
    let totalZero = false;
    let timesheetTotal = 0;
    let emptyTaskNameFlag = false;
    inputList.map((tsheet, index) => {
      taskTotal = (strTimeToMins(tsheet.day1) + strTimeToMins(tsheet.day2) + strTimeToMins(tsheet.day3) + strTimeToMins(tsheet.day4) + strTimeToMins(tsheet.day5) + strTimeToMins(tsheet.day6) + strTimeToMins(tsheet.day7))
      timesheetTotal = timesheetTotal + (strTimeToMins(tsheet.day1) + strTimeToMins(tsheet.day2) + strTimeToMins(tsheet.day3) + strTimeToMins(tsheet.day4) + strTimeToMins(tsheet.day5) + strTimeToMins(tsheet.day6) + strTimeToMins(tsheet.day7))
      if (taskTotal == 0) {
        totalZero = true;
        timesheet = { ...tsheet };
        if (tsheet.task == '') {
          emptyTaskNameFlag = true;
        }
      } else {
        taskdata1.push(
          {
            "isDefaultTask": false,
            "projectId": tsheet.project,
            "taskId": tsheet.task,
            "id": tsheet.timesheetId,
            //"projectManagerId": 417,
            "timelog": [
              {
                "dayofweek": 1,
                "hours": tsheet.day1,
                "id": tsheet.tlog_day1,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(0).format("YYYY-MM-DD"),
                //"comments": ""
              },
              {
                "dayofweek": 2,
                "hours": tsheet.day2,
                "id": tsheet.tlog_day2,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(1).format("YYYY-MM-DD"),
                //"comments": ""
              },
              {
                "dayofweek": 3,
                "hours": tsheet.day3,
                "id": tsheet.tlog_day3,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(2).format("YYYY-MM-DD"),
                //"comments": ""
              },
              {
                "dayofweek": 4,
                "hours": tsheet.day4,
                "id": tsheet.tlog_day4,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(3).format("YYYY-MM-DD"),
                //"comments": ""
              },
              {
                "dayofweek": 5,
                "hours": tsheet.day5,
                "id": tsheet.tlog_day5,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(4).format("YYYY-MM-DD"),
                //"comments": ""
              },
              {
                "dayofweek": 6,
                "hours": tsheet.day6,
                "id": tsheet.tlog_day6,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(5).format("YYYY-MM-DD"),
                // "comments": ""
              },
              {
                "dayofweek": 7,
                "hours": tsheet.day7,
                "id": tsheet.tlog_day7,
                "date": moment().weekYear(yearNumber).week(weekNumber).day(6).format("YYYY-MM-DD"),
                //"comments": ""
              }
            ]
          })
      }
    })

    if (emptyTaskNameFlag) {
      message.error(`Empty task name not allowed`);
      return;
    }
    if (validateTasks()) {
      return;
    }

    if (!totalZero && !exceedHrsDay() && !checkForDuplicateTasks() && taskdata1.length > 0 && taskTotal > 0) {
      axios.post(API_URL + '/api/timesheets/v2/createOrUpdate', {
        "year": yearNumber,
        "weekNumber": weekNumber,
        "userId": userDetails.id,
        "reportingManagerId": userDetails.reportingManagerNm,
        "taskData": taskdata1,
        "deletedTaskData": deletedTaskData,
        "timesheetComment": comments,
      },
        {
          headers: {
            'x-access-token': `${token}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(response => {
          message.success("Timesheet Saved Successfully");
          history.push('/timesheets/submit?' + new Date().getTime());
        })
        .catch(error => {
          if (error.response) {
            message.error(error.response.data.error)
          } else {
            message.error("Error In Saving timesheet", error)
          }
        });
    } else {
      if (taskdata1.length == 0) {
        message.error("Please add task line items to the timesheet");
      }
      if (totalZero) {
        projectTaskList.map((projectItem, index) => {
          projectItem.children.map((taskItem, index) => {
            if (taskItem.value == `${timesheet.task}`)
              message.error(`Task hours not entered for "${taskItem.label}"`);
          })
        })
      }

      if (exceedHrsDay())
        message.error("Please don't log more than 12 hours/day");

      if (checkForDuplicateTasks())
        message.error("Please don't log hours for duplicate tasks");

    }
  }
  // handle input change
  const handleInputChange = (time, timeString, e, index, title) => {
    const list = [...inputList];
    if (title === "day1" || title === "day2" || title === "day3" || title === "day4" || title === "day5" || title === "day6" || title === "day7") {
      list[index][title] = time ? time.format("HH:mm") : null;
    } else if (title == "project") {
      list[index]['project'] = e[0];
      list[index]['task'] = e[1];
    }
    setInputList(list);
  }

  // handle click event of the Remove button   const [deletedTaskData, setDeletedTaskData] = useState([]);
  const handleRemoveClick = index => {
    const list = [...inputList];
    setDeletedTaskData([...deletedTaskData, inputList[index].task])
    list.splice(index, 1);
    setInputList(list);
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { project: "", task: "", day1: "00:00", day2: "00:00", day3: "00:00", day4: "00:00", day5: "00:00", day6: "00:00", day7: "00:00" }]);
  }

  const getWeeklyTS = async () => {
    const url = API_URL + `/api/timesheets/v2/getUserTimesheet?userId=${userDetails.id}&year=${props.match.params.year}&weekNumber=${props.match.params.week}`;
    try {
      const res = await axios.get(url,
        {
          headers: {
            'x-access-token': `${token}`,
            'Access-Control-Allow-Origin': '*'
          }
        });
      if (res.status === 200) {
        if (Object.keys(res.data.data).length > 0) {
          let strPMComments = ''
          let stepData = {
          }

          let tsheets = [];
          let flag = true;
          let timesheetStatus = 0;
          //setDisableFlag(res.data.data.isTimesheetSubmitted)
          res.data.data.timesheet.map((tsheet, index) => {
            stepData.employeeId = tsheet.user.employeeId
            stepData.displayNameTx = tsheet.user.displayNameTx
            stepData.reportingManager = {}
            stepData.reportingManager.employeeId = tsheet.reportingManager.employeeId
            stepData.reportingManager.displayNameTx = tsheet.reportingManager.displayNameTx
            stepData.reportingManager.reportingManagerAction = tsheet.reportingManagerAction

            stepData.projectManagers = []
            tsheet.timesheetComment = tsheet.timesheetComment || ''
            setComments(tsheet.timesheetComment);
            setTextAreaCount(tsheet.timesheetComment.length)
            setRMComments(tsheet.reportingManagerComment || '');
            timesheetStatus = tsheet.timesheetStatus;
            let rmAction = tsheet.reportingManagerAction;
            tsheet.projects.map((projectItem, index) => {
              let tempObj = undefined
              if (projectItem.projectManager.employeeId !== stepData.reportingManager.employeeId) {
                tempObj = {
                  employeeId: projectItem.projectManager.employeeId,
                  displayNameTx: projectItem.projectManager.displayNameTx,
                  projectManagerAction: projectItem.projectManagerAction,
                  taskList: []
                }
                stepData.projectManagers.push(tempObj)
              }

              projectItem.tasks.map((taskItem, index) => {
                if (taskItem.projectManagerAction === 'Rejected') {
                  flag = false;
                }
                let pmName = 'PM: ' + projectItem.projectManager.displayNameTx || ''
                if (tsheet.reportingManager.id == projectItem.projectManager.id) {
                  pmName = 'RM: ' + tsheet.reportingManager.displayNameTx || ''
                }

                if(tempObj) {
                  tempObj.taskList.push({
                    tName: taskItem.task.taskNameTx,
                    status: taskItem.projectManagerAction
                  })
                }
                if (tsheet.reportingManager.id != projectItem.projectManager.id && taskItem.task && taskItem.task.taskNameTx) {
                  strPMComments = strPMComments + projectItem.project.name + '/' + taskItem.task.taskNameTx + " : \n\t" + (taskItem.projectManagerComment || '') + "\n"
                }

                tsheets = [...tsheets, {
                  "project": projectItem.project && projectItem.project.id,
                  "task": taskItem.task && taskItem.task.id,
                  "timesheetId": taskItem.timesheetId,
                  "taskname": taskItem.task && taskItem.task.taskNameTx,
                  "day1": taskItem.timelog[0].hours,
                  "tlog_day1": taskItem.timelog[0].timelogId,
                  "day2": taskItem.timelog[1].hours,
                  "tlog_day2": taskItem.timelog[1].timelogId,
                  "day3": taskItem.timelog[2].hours,
                  "tlog_day3": taskItem.timelog[2].timelogId,
                  "day4": taskItem.timelog[3].hours,
                  "tlog_day4": taskItem.timelog[3].timelogId,
                  "day5": taskItem.timelog[4].hours,
                  "tlog_day5": taskItem.timelog[4].timelogId,
                  "day6": taskItem.timelog[5].hours,
                  "tlog_day6": taskItem.timelog[5].timelogId,
                  "day7": taskItem.timelog[6].hours,
                  "tlog_day7": taskItem.timelog[6].timelogId,
                  "disabled": timesheetStatus == 0 ? false : taskItem.projectManagerAction !== 'Rejected',
                  "projectManagerAction": taskItem.projectManagerAction,
                  "pmName": pmName,
                  "taskstatus": (rmAction == 'Pending') ? "pm" + taskItem.projectManagerAction.toLowerCase() : rmAction.toLowerCase(),
                  "pmcomments": taskItem.projectManagerComment,
                }]
              })
            })
          })
          if (timesheetStatus == 0) {
            setDisableFlag(false);
            setSaveButtonFlag(!flag);
          } else {

            let status = { P: 0, A: 0, R: 0 }
            stepData.projectManagers.map(s => {
              if (s.projectManagerAction == 'Pending') {
                status.P = status.P + 1
              } else if (s.projectManagerAction == 'Approved') {
                status.A = status.A + 1
              } else if (s.projectManagerAction == 'Rejected') {
                status.R = status.R + 1
              }
            });
            stepData.pmDescription = ""
            stepData.rmDescription = "Waiting"
            stepData.pmStatus = ''
            stepData.rmStatus = ''
            stepData.current = 1
            if (stepData.reportingManager.reportingManagerAction == 'Rejected') {
              stepData.pmDescription = "Rejected By RM"
              stepData.pmStatus = 'error'
              stepData.rmStatus = 'error'
            } else if ((stepData.reportingManager.reportingManagerAction == 'Approved') || (status.P == 0 && status.R == 0)) {
              stepData.pmDescription = "Approved"
              stepData.rmDescription = "Pending"
            } else if (status.P > 0 && status.R > 0 && status.A > 0) {
              stepData.pmDescription = 'In Progress';//"P(" + status.P + "), A(" + status.A + "), R(" + status.R + ")"
            } else if (status.R > 0 && status.A > 0) {
              stepData.pmDescription = 'In Progress';//"A(" + status.A + "), R(" + status.R + ")"
            } else if (status.A > 0 && status.P > 0) {
              stepData.pmDescription = 'In Progress';//"A(" + status.A + "), P(" + status.P + ")"
            } else if (status.A > 0 && status.R > 0) {
              stepData.pmDescription = 'In Progress';//"A(" + status.A + "), R(" + status.R + ")"
            } else if (status.R > 0) {
              stepData.pmDescription = "Rejected"
              stepData.current = 0
              stepData.pmStatus = 'error'
            } else if (status.P > 0) {
              stepData.pmDescription = "Pending"
              stepData.current = 1
            } else if (status.A > 0) {
              stepData.pmDescription = "Approved"
              stepData.current = 2
            }
            if (stepData.reportingManager.reportingManagerAction == 'Approved') {
              stepData.rmDescription = "Approved"
              stepData.current = 3
              stepData.rmStatus = 'finish'
            } else if (stepData.reportingManager.reportingManagerAction == 'Rejected') {
              stepData.rmDescription = "Rejected"
              stepData.current = 0
            }

            setStepData(stepData)
            setDisableFlag(flag);
            setSaveButtonFlag(!flag);
          }
          setPMComments(strPMComments)
          tsheets.sort((a, b) => {
            return a.timesheetId - b.timesheetId;
          });
          setInputList(tsheets);
        }
      } else return {};
    } catch (ex) {
      message.error(`${ex.message}:get timesheet`);
      return {}
    }
  }

  let data = [];
  const getProjectList = async () => {
    const url = API_URL + '/api/project/v2/get/assigned/projects/' + userDetails.id;
    try {
      const res = await axios.get(url,
        {
          headers: {
            'x-access-token': `${token}`,
            'Access-Control-Allow-Origin': '*'
          }
        });
      if (res.status === 200) {
        if (Object.keys(res.data.data).length > 0) {
          let assignedprojects = [];
          res.data.data.map((item, index) => (
            assignedprojects = item.projects
          ))

          assignedprojects.map((project, index) => (
            getTaskLists(project)
          ))
        }
      } else return {};
    } catch (ex) {
      return {}
    }
  }

  const getTaskLists = async (project) => {
    const url = API_URL + `/api/task/getUsersTasksList?userId=${userDetails.id}&projectId=${project.id}&limit=100&offset=0`;
    try {
      const res = await axios.get(url,
        {
          headers: {
            'x-access-token': `${token}`,
            'Access-Control-Allow-Origin': '*'
          }
        });
      if (res.status === 200) {
        if (Object.keys(res.data.data).length > 0) {
          let data1 = [];
          let childTask = [];
          res.data.data.map((task, index) => {
            if(task.taskStatusNm !== 5) {
              childTask.push({
                disabled: task.taskStatusNm == 5 ? true : false,
                value: task.id,
                label: task.taskNameTx,
              })
            }
        })
          data1 = {
            ...data1,
            value: project.id,
            label: project.name,
            children: childTask,
          };
          if(childTask.length) {
            data = [...data, data1];
            setProjectTaskList(data);
          }
        }

      } else if (res.status === 400) {

      }
    } catch (ex) {
      return {}
    }
  }

  const displayProjectMgrTasks = (pm) => {
    if(pm && pm.taskList) {
      return pm.taskList.map((t,i) => {
        return <div key={i}><b>{t.tName}</b> &nbsp; { t.status == 'Approved' ?  <Button className="approved_btn" shape="circle" htmlType="submit" icon={<CheckOutlined />} > </Button> : t.status == 'Rejected' ? <Button className="reject_btn" shape="circle" htmlType="submit" icon={<CloseOutlined />} > </Button> : 'Pending' } </div>
      })
    } else {
      return ''
    }
   }

  useEffect(() => {
    getProjectList();
    getWeeklyTS();
  }, [])

  return (
    <Auxiliary>
      <Widget>
        {stepData &&
          <Collapse bordered={false} defaultActiveKey={['1']} expandIconPosition='right' className="mainAcc">
            <Panel key="1"
              extra={
                <ul className="gx-list-inline gx-ml-auto gx-mb-0 gx-text-grey accStyle1">
                  <li className=""><p className="gx-mb-0"><Tag className="statusColor gx-p-0 font600">Timesheet Status</Tag></p></li>
                </ul>
              }>

              {stepData.projectManagers.length > 0
                ?
                <Steps current={stepData.current}>
                  <Step title="You" description="Submitted" icon={(() => {
                    try {
                      return <Tooltip title={`${stepData.displayNameTx}`} >
                        <Avatar shape="circle" src={require(`../../../../assets/images/avatar/${stepData.employeeId.toLowerCase()}.png`)} />
                      </Tooltip>
                    } catch (err) {
                      try {
                        return <Tooltip title={`${stepData.displayNameTx}`} ><Avatar shape="circle" src={require(`../../../../assets/images/avatar/profile.png`)} /></Tooltip>
                      } catch (err) {
                        return <li><span className="ant-avatar gx-border gx-border-grey gx-bg-transparent gx-text-grey gx-d-flex gx-align-items-center gx-justify-content-center"><i
                          className="icon icon-add" /></span></li>
                      }
                    }
                  })()}></Step>

                  <Step title="Project Manager(s)" description={`${stepData.pmDescription}`} status={stepData.pmStatus} icon={
                    
                    (() => 
                  {
                    let pManagers = stepData.projectManagers.map((s, i) => {
                      try {
                        return <Tooltip key={i} title={`${s.displayNameTx}`} >
                          <Popover trigger="click" content={()=> { return displayProjectMgrTasks(s); }} title="Task Status">
                          <Avatar shape="circle" src={require(`../../../../assets/images/avatar/${s.employeeId.toLowerCase()}.png`)} />
                          </Popover>
                          </Tooltip>
                      } catch (err) {
                        try {
                          return <Tooltip key={i} title={`${s.displayNameTx}`} >
                            <Popover trigger="click" content={()=> { return displayProjectMgrTasks(s); }} title="Task Status">
                            <Avatar shape="circle" src={require(`../../../../assets/images/avatar/profile.png`)} />
                            </Popover>
                            </Tooltip>
                        } catch (err) {
                          return <li key={i}><span className="ant-avatar gx-border gx-border-grey gx-bg-transparent gx-text-grey gx-d-flex gx-align-items-center gx-justify-content-center"><i
                            className="icon icon-add" /></span></li>
                        }
                      }
                    })
                    return pManagers
                  })()}></Step>

                  <Step title="Resource Manager"
                    status={stepData.rmStatus}
                    description={`${stepData.rmDescription}`} icon={(() => {
                      try {
                        return <Tooltip title={`${stepData.reportingManager.displayNameTx}`} ><Avatar shape="circle" src={require(`../../../../assets/images/avatar/${stepData.reportingManager.employeeId.toLowerCase()}.png`)} /></Tooltip>
                      } catch (err) {
                        try {
                          return <Tooltip title={`${stepData.reportingManager.displayNameTx}`} ><Avatar shape="circle" src={require(`../../../../assets/images/avatar/profile.png`)} /></Tooltip>
                        } catch (err) {
                          return <li><span className="ant-avatar gx-border gx-border-grey gx-bg-transparent gx-text-grey gx-d-flex gx-align-items-center gx-justify-content-center"><i
                            className="icon icon-add" /></span></li>
                        }
                      }
                    })()}></Step>
                </Steps>
                :
                <Steps current={2}>
                  <Step title="You" icon={(() => {
                    try {
                      return <Tooltip title={`${stepData.displayNameTx}`} ><Avatar shape="circle" src={require(`../../../../assets/images/avatar/${stepData.employeeId.toLowerCase()}.png`)} /></Tooltip>
                    } catch (err) {
                      try {
                        return <Tooltip title={`${stepData.displayNameTx}`} ><Avatar shape="circle" src={require(`../../../../assets/images/avatar/profile.png`)} /></Tooltip>
                      } catch (err) {
                        return <li><span className="ant-avatar gx-border gx-border-grey gx-bg-transparent gx-text-grey gx-d-flex gx-align-items-center gx-justify-content-center"><i
                          className="icon icon-add" /></span></li>
                      }
                    }
                  })()}></Step>

                  <Step title="Reporting Manager"
                    status={`${stepData.reportingManager.reportingManagerAction}=='Rejected' ? 'error' : ''`}
                    description={`${stepData.reportingManager.reportingManagerAction}`} icon={(() => {
                      try {
                        return <Tooltip title={`${stepData.reportingManager.displayNameTx}`} ><Avatar shape="circle" src={require(`../../../../assets/images/avatar/${stepData.reportingManager.employeeId.toLowerCase()}.png`)} /></Tooltip>
                      } catch (err) {
                        try {
                          return <Tooltip title={`${stepData.reportingManager.displayNameTx}`} ><Avatar shape="circle" src={require(`../../../../assets/images/avatar/profile.png`)} /></Tooltip>
                        } catch (err) {
                          return <li><span className="ant-avatar gx-border gx-border-grey gx-bg-transparent gx-text-grey gx-d-flex gx-align-items-center gx-justify-content-center"><i
                            className="icon icon-add" /></span></li>
                        }
                      }
                    })()}></Step>
                </Steps>
              }
            </Panel>
          </Collapse>
        }

        <Row className="dateRangeBlock m_0">
          <Col xl={8} lg={8} md={8} sm={8} xs={8} className="timesheetHeading1 gx-pl-0">Enter Your Timesheet</Col>
          <Col xl={8} lg={8} md={8} sm={8} xs={8} className="gx-text-center"><h4>
            {
              //  weekNumber >= 48 ?
              //  weekNumber == 48 ? moment().year(yearNumber - 1).week(weekNumber).startOf('week').format('MMM D, YYYY') + " - " + moment().year(yearNumber - 1).week(weekNumber).endOf('week').format('MMM D, YYYY') : moment().year(yearNumber - 1).week(weekNumber).startOf('week').format('MMM D, YYYY') + " - " + moment().year(yearNumber).week(weekNumber).endOf('week').format('MMM D, YYYY') :
              moment().weekYear(yearNumber).week(weekNumber).startOf('week').format('MMM D, YYYY') + " - " + moment().weekYear(yearNumber).week(weekNumber).endOf('week').format('MMM D, YYYY')
            }
          </h4></Col>
          <Col xl={8} lg={8} md={8} sm={8} xs={8} className="flex_style gx-pr-0">
            <DatePicker picker="week"  onChange={onChangeWeek} disabled={true} defaultValue={moment().weekYear(yearNumber).week(weekNumber)} bordered={true} />
          </Col>
        </Row>

        <div className="timesheetBlock">
          <Row className="timesheetHeader m_0">
            <Col xl={8} lg={8} md={4} sm={4} xs={4} className="timesheetHeading upperCaseStyle timeSheetMiddle borderRight">projects/Tasks</Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center weekdays_bg ">
              <p className="weekStyle">S</p>
              <p className="monthDateStyle">
                {/* {moment().week(weekNumber).day(0).format('MMM D')} */}
                {
                  //   weekNumber >= 48 ?
                  //     weekNumber == 48 ? moment().year(yearNumber - 1).week(weekNumber).day(0).format('MMM D') : moment().year(yearNumber - 1).week(weekNumber).day(0).format('MMM D') :
                  moment().weekYear(yearNumber).week(weekNumber).day(0).format('MMM D')}
              </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
              <p className="weekStyle">M</p>
              <p className="monthDateStyle">
                {/* {moment().week(weekNumber).day(1).format('MMM D')} */}
                {
                  //   weekNumber >= 48 ?
                  //    weekNumber == 48 ? moment().year(yearNumber - 1).week(weekNumber).day(1).format('MMM D') : moment().year(yearNumber - 1).week(weekNumber).day(1).format('MMM D') :
                  moment().weekYear(yearNumber).week(weekNumber).day(1).format('MMM D')}
              </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
              <p className="weekStyle">T</p>
              <p className="monthDateStyle">
                {/* {moment().week(weekNumber).day(2).format('MMM D')} */}
                {
                  // weekNumber >= 48 ?
                  // weekNumber == 48 ? moment().year(yearNumber - 1).week(weekNumber).day(2).format('MMM D') : moment().year(yearNumber - 1).week(weekNumber).day(2).format('MMM D') :
                  moment().weekYear(yearNumber).week(weekNumber).day(2).format('MMM D')}

              </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
              <p className="weekStyle">W</p>
              <p className="monthDateStyle">

                {/* {moment().week(weekNumber).day(3).format('MMM D')} */}
                {
                  // weekNumber >= 48 ?
                  //  weekNumber == 48 ? moment().year(yearNumber - 1).week(weekNumber).day(3).format('MMM D') : moment().year(yearNumber - 1).week(weekNumber).day(3).format('MMM D') :
                  moment().weekYear(yearNumber).week(weekNumber).day(3).format('MMM D')}
              </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
              <p className="weekStyle">T</p>
              <p className="monthDateStyle">
                {
                  //  weekNumber >= 48 ?
                  //    weekNumber == 48 ? moment().year(yearNumber - 1).week(weekNumber).day(4).format('MMM D') : moment().year(yearNumber - 1).week(weekNumber).day(4).format('MMM D') :
                  moment().weekYear(yearNumber).week(weekNumber).day(4).format('MMM D')}
                {/* {moment().week(weekNumber).day(4).format('MMM D')} */}
              </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center">
              <p className="weekStyle">F</p>
              <p className="monthDateStyle">
                {
                  //  weekNumber >= 48 ?
                  // weekNumber == 48 ? moment().year(yearNumber - 1).week(weekNumber).day(5).format('MMM D') : moment().year(yearNumber - 1).week(weekNumber).day(5).format('MMM D') :
                  moment().weekYear(yearNumber).week(weekNumber).day(5).format('MMM D')}
                {/* {moment().week(weekNumber).day(5).format('MMM D')} */}
              </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center weekdays_bg ">
              <p className="weekStyle">S</p>
              <p className="monthDateStyle">
                {/* {moment().week(weekNumber).day(6).format('MMM D')} */}
                {
                  //weekNumber >= 48 ?
                  //  weekNumber == 48 ? moment().year(yearNumber - 1).week(weekNumber).day(6).format('MMM D') : moment().year(yearNumber - 1).week(weekNumber).day(6).format('MMM D') :
                  moment().weekYear(yearNumber).week(weekNumber).day(6).format('MMM D')}
              </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="total_data gx-text-center timeSheetMiddle">
              <span className="weekStyle">Total</span>
            </Col>
          </Row>
        </div>
        <div className="App timesheetBody">
          {inputList.map((x, i) => {
            return (
              <div className="box" key={i}>
                <Row className="m_0 spacing_bottom timesheetBodyBlock border_bottom">
                  <Col xl={8} lg={8} md={4} sm={4} xs={4} className="borderRight">

                    <Tooltip title={`${x.pmName}`}>
                      <Cascader className={`${x.taskstatus}`}
                        options={projectTaskList}
                        onChange={e => handleInputChange(null, null, e, i, "project")}
                        placeholder="Please select"
                        value={[x.project, x.task]}
                        disabled={x.disabled}
                      />
                    </Tooltip>
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells weekdays_bg timepicker">
                    <TimePicker className={`${x.taskstatus}`}
                      format="HH:mm"
                      disabled={x.disabled}
                      name="day1"
                      suffixIcon={null}
                      showNow={false}
                      inputReadOnly={true}
                      placeholder="00:00"
                      minuteStep={15}
                      value={moment(x.day1, 'HH:mm')}
                      onSelect={(time) => handleInputChange(time, null, null, i, "day1")}
                      disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells timepicker">
                    <TimePicker
                      format="HH:mm"
                      disabled={x.disabled}
                      name="day2"
                      suffixIcon={null}
                      showNow={false}
                      inputReadOnly={true}
                      placeholder="00:00"
                      minuteStep={15}
                      value={moment(x.day2, 'HH:mm')}
                      onSelect={(time) => handleInputChange(time, null, null, i, "day2")}
                      disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells timepicker">
                    <TimePicker
                      format="HH:mm"
                      disabled={x.disabled}
                      name="day3"
                      suffixIcon={null}
                      showNow={false}
                      inputReadOnly={true}
                      placeholder="00:00"
                      minuteStep={15}
                      value={moment(x.day3, 'HH:mm')}
                      onSelect={(time) => handleInputChange(time, null, null, i, "day3")}
                      disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells timepicker">
                    <TimePicker
                      format="HH:mm"
                      disabled={x.disabled}
                      name="day4"
                      suffixIcon={null}
                      showNow={false}
                      inputReadOnly={true}
                      placeholder="00:00"
                      minuteStep={15}
                      value={moment(x.day4, 'HH:mm')}
                      onSelect={(time) => handleInputChange(time, null, null, i, "day4")}
                      disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells timepicker">
                    <TimePicker
                      format="HH:mm"
                      disabled={x.disabled}
                      name="day5"
                      suffixIcon={null}
                      showNow={false}
                      inputReadOnly={true}
                      placeholder="00:00"
                      minuteStep={15}
                      value={moment(x.day5, 'HH:mm')}
                      onSelect={(time) => handleInputChange(time, null, null, i, "day5")}
                      disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells timepicker">
                    <TimePicker
                      format="HH:mm"
                      disabled={x.disabled}
                      name="day6"
                      suffixIcon={null}
                      showNow={false}
                      inputReadOnly={true}
                      placeholder="00:00"
                      minuteStep={15}
                      value={moment(x.day6, 'HH:mm')}
                      onSelect={(time) => handleInputChange(time, null, null, i, "day6")}
                      disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={2} xs={2} className="timesheettableCells weekdays_bg ">
                    <TimePicker
                      format="HH:mm"
                      disabled={x.disabled}
                      name="day7"
                      suffixIcon={null}
                      showNow={false}
                      inputReadOnly={true}
                      placeholder="00:00"
                      minuteStep={15}
                      value={moment(x.day7, 'HH:mm')}
                      onSelect={(time) => handleInputChange(time, null, null, i, "day7")}
                      disabledHours={() => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                    />
                  </Col>
                  <Col xl={2} lg={2} md={2} sm={2} xs={2} className="total_value timeSheetMiddle">
                    <span>{
                      minsToStrTime(strTimeToMins(x.day1) + strTimeToMins(x.day2) + strTimeToMins(x.day3) + strTimeToMins(x.day4) + strTimeToMins(x.day5) + strTimeToMins(x.day6) + strTimeToMins(x.day7))
                    }</span>
                    {(() => {
                      if (!x.disabled)
                        return (<MinusCircleOutlined onClick={() => handleRemoveClick(i)} />);
                    })()}
                  </Col>
                </Row>
              </div>
            );
          })}
          <Row className="">
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              {(() => {
                if (!disableFlag) {
                  return (<Button type="text" disabled={disableFlag} onClick={handleAddClick} className="gx-mt-3 addBtn"><i className="fa fa-plus" aria-hidden="true"></i>Tasks</Button>)
                }
              })()}
            </Col>
          </Row>
        </div>
        <div className="timesheetBlock">
          <Row className="timesheetHeader m_0 timeSheetMiddle">
            <Col xl={8} lg={8} md={8} sm={8} xs={8} className="timesheetHeading upperCaseStyle timeSheetMiddle borderRight font600">Total Hours</Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
              <p>
                {(() => {
                  let total = 0;
                  inputList.map((x, i) => {
                    total = total + strTimeToMins(x.day1)
                  })
                  return minsToStrTime(total);
                })()} </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
              <p>
                {(() => {
                  let total = 0;
                  inputList.map((x, i) => {
                    total = total + strTimeToMins(x.day2)
                  })
                  return minsToStrTime(total);
                })()} </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
              <p>
                {(() => {
                  let total = 0;
                  inputList.map((x, i) => {
                    total = total + strTimeToMins(x.day3)
                  })
                  return minsToStrTime(total);
                })()} </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
              <p>
                {(() => {
                  let total = 0;
                  inputList.map((x, i) => {
                    total = total + strTimeToMins(x.day4)
                  })
                  return minsToStrTime(total);
                })()} </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
              <p>
                {(() => {
                  let total = 0;
                  inputList.map((x, i) => {
                    total = total + strTimeToMins(x.day5)
                  })
                  return minsToStrTime(total);
                })()} </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
              <p>
                {(() => {
                  let total = 0;
                  inputList.map((x, i) => {
                    total = total + strTimeToMins(x.day6)
                  })
                  return minsToStrTime(total);
                })()} </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="align_center totalcount">
              <p>
                {(() => {
                  let total = 0;
                  inputList.map((x, i) => {
                    total = total + strTimeToMins(x.day7)
                  })
                  return minsToStrTime(total);
                })()} </p>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2} className="totalcountMain gx-text-center">
              <p>
                {(() => {
                  let total = 0;
                  inputList.map((x, i) => {
                    total = total + (strTimeToMins(x.day1) + strTimeToMins(x.day2) + strTimeToMins(x.day3) + strTimeToMins(x.day4) + strTimeToMins(x.day5) + strTimeToMins(x.day6) + strTimeToMins(x.day7))
                  })
                  return minsToStrTime(total);
                })()} </p>
            </Col>
          </Row>
        </div>
        <Row className="m_0" >
          <Col xl={7} lg={7} md={7} sm={7} xs={7} className=" gx-pl-0 gx-pr-0">
            <>
              <center><p><b>Resource Manager</b></p></center>
              <TextArea rows={4} value={rmComments} readOnly={true} />
            </>
          </Col>
          <Col xl={1} lg={1} md={1} sm={1} xs={1} className=" gx-pl-0 gx-pr-0">
          </Col>
          <Col xl={7} lg={7} md={7} sm={7} xs={7} className=" gx-pl-0 gx-pr-0">
            <>
              <center><p><b>Project Manager</b></p></center>
              <TextArea rows={4} value={pmComments} readOnly={true} />
            </>
          </Col>
          <Col xl={1} lg={1} md={1} sm={1} xs={1} className=" gx-pl-0 gx-pr-0">
          </Col>
          <Col xl={7} lg={7} md={7} sm={7} xs={7} className=" gx-pl-0 gx-pr-0">
            {disableFlag == true ?
              <>
                <center><p><b>Employee</b></p></center>
                <TextArea placeholder="Please enter the comments" readOnly={disableFlag} rows={4} value={comments} />
              </>
              :
              <>
                <center><p><b>Employee</b></p></center>
                <TextArea placeholder="Please enter the comments" disabled={disableFlag} onChange={e => handleComments(e)} rows={4} value={comments} maxLength={255} className={disableFlag == true ? 'backgroundWhite' : ''} />
                <p style={{ textAlign: 'right' }}> {`${textAreaCount} / 255`} </p>
              </>
            }
          </Col>
          <Col xl={2} lg={2} md={2} sm={2} xs={2} className=" gx-pl-0 gx-pr-0">
          </Col>
        </Row>

        <Row className="m_0 ">
          <Col xl={8} lg={8} md={8} sm={8} xs={8} className="gx-p-0">
            <Button type="primary" className="gx-mr-2 scale-x" onClick={() => history.push('/timesheets/submit/?' + new Date().getTime())}>Back</Button>
          </Col>
          <Col xl={16} lg={16} md={16} sm={16} xs={16} className="gx-p-0 buttonGroup">
            {(() => {
              if (!disableFlag) {
                return (<>
                  <Button type="primary" disabled={disableFlag || saveButtonFlag} className="submit_btn scale-x" onClick={onFinish} htmlType="save">Save </Button>
                  <Button type="primary" disabled={disableFlag} className="save_btn scale-x" onClick={validateSubmitTimesheet} htmlType="submit">Submit</Button>
                </>)
              }
            })()}
          </Col>
        </Row>

        <Modal closable={false} centered title="Confirmation" visible={isModalOpen} onOk={() => { setIsModalOpen(false); submitTimesheet();}}
            onCancel={() => { setIsModalOpen(false);}}
            wrapClassName={"modal-ticket-update" } keyboard={false} maskClosable={false}>
            <p>You are submitting the timesheet early for the current week <b>{moment().week()}</b>.</p>
              <center><h5>{
                moment().weekYear(yearNumber).week(weekNumber).startOf('week').format('MMM D, YYYY') + " - " + moment().weekYear(yearNumber).week(weekNumber).endOf('week').format('MMM D, YYYY')
              }</h5></center>
              Are you sure to submit the timesheet ?
        </Modal>
      </Widget>
    </Auxiliary>
  )
}
export default TSApp;