import React, { useEffect, useState } from "react";
import { Col, Row, Table, message, Button, Form, Select, Input, Modal, Checkbox } from "antd";
import axios from 'axios';
import Auxiliary from "../../../util/Auxiliary";
import { API_URL } from '../../../constants/APIConfig';
import { PlusOutlined } from '@ant-design/icons';
import { FormOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/min/locales';

const { Option } = Select;

const MyAppsHome = () => {
  const [form] = Form.useForm()
  const [skillForm] = Form.useForm()
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'))
  const token = sessionStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [userSkillList, setUserSkillList] = useState([])
  const [editSkillId, setEditSkillId] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const [allDepartment, setAllDepartment] = useState([])

  const [allSkills, setAllSkills] = useState([])
  const [allSkillsTemp, setAllSkillsTemp] = useState([])

  const [allSubSkills, setAllSubSkills] = useState([])
  const [allDepSkillSubSkills, setAllDepSkillSubSkills] = useState([])

  const relevantYears = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
  const relevantMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]

  const props = {
    action: API_URL + '/api/users/profile-upload',
    headers: {
      'x-access-token': `${token}`,
      'Access-Control-Allow-Origin': '*',
      'emp-id': `${userDetails.employeeId}`,
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`Profile picture uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`Profile picture file upload failed.`);
      }
    },
  }

  const openEditModal = (record) => {

    let allSkills = []
    allSkillsTemp.forEach(s => {
      if (s.departmentIdNm === record.assignedSkill.departmentIdNm) {
        allSkills.push(s)
      }
    })
    setAllSkills(allSkills)

    let subSkills = []
    allDepSkillSubSkills.forEach(s => {
      if (s.skillIdNm === record.assignedSkill.skillIdNm) {
        subSkills.push(s)
      }
    })
    setAllSubSkills(subSkills)
    let expArr = (record.relevantExp + '').split('.')
    skillForm.setFieldsValue({
      departmentId: record.assignedSkill.departmentIdNm,
      skillId: record.assignedSkill.skillIdNm,
      subSkillId: record.subSkillId,
      selfAssessment: record.selfAssessment,
      relevantYearsExp: parseInt(expArr[0]),
      relevantMonthsExp: expArr[1] ? parseInt(expArr[1]) : 0,
      isPrimary: record.isPrimary,
      isCertified: record.isCertified,
    })
    setEditSkillId(record.id)
    setIsModalOpen(true)
  }

  const openDeleteModal = (record) => {
    setEditSkillId(record.id)
    setIsDeleteModalOpen(true);
  }

  const userSkillListCols = [
    {
      title: 'Department', dataIndex: 'name', width: 150,
      render: (text, record) => {
        return (
          <div>
            <span> {record.assignedSkill.sb_department.nameTx}</span>
          </div>
        );
      },
    },
    {
      title: 'Skill', dataIndex: 'name', width: 150,
      render: (text, record) => {
        return (
          <div>
            <span> {record.assignedSkill.sb_skill.nameTx}</span>
          </div>
        );
      },
    },
    {
      title: 'Sub Skill', dataIndex: 'name', width: 150,
      render: (text, record) => {
        return (
          <div>
            <span> {record.assignedSkill.nameTx}</span>
          </div>
        );
      },
    },
    {
      title: 'Self Assessment', dataIndex: 'selfAssessment', width: 100,
      render: (text, record) => {
        return (
          <div>
            <span> {text}</span>
          </div>
        );
      },
    },
    {
      title: 'Relevant Experience', dataIndex: 'relevantExp', width: 100,
      render: (text, record) => {
        return (
          <div>
            <span> <b>{text}</b> Yrs</span>
          </div>
        );
      },
    },
    {
      title: 'Is Primary Skill', dataIndex: 'isPrimary', width: 100,
      render: (text, record) => {
        return (
          <div>
            <span> {text ? 'Yes' : 'No'} </span>
          </div>
        );
      },
    },
    {
      title: 'Is Certified', dataIndex: 'isCertified', width: 100,
      render: (text, record) => {
        return (
          <div>
            <span> {text ? 'Yes' : 'No'} </span>
          </div>
        );
      },
    },
    {
      title: 'Action', fixed: 'right', width: 140, dataIndex: 'action',
      render: (text, record) =>
        <div className="gx-d-flex">
          <Button className="approved_btn scale-x" title="Edit" shape="circle" htmlType="submit" icon={<FormOutlined />} onClick={() => openEditModal(record)}> </Button>
          <Button className="reject_btn scale-x" title="Delete" shape="circle" htmlType="submit" icon={<DeleteOutlined />} onClick={() => openDeleteModal(record)}> </Button>
        </div>
    }
  ]

  const openSkillForm = () => {
    setIsModalOpen(true)
  }

  const onProfileFinish = () => {
    updateUserProfileDetails(form.getFieldsValue())
  }

  const onSkillFormFinish = () => {
    let values = skillForm.getFieldsValue()
    values['userId'] = userDetails.id
    values['relevantExp'] = values['relevantYearsExp'] + '.' + values['relevantMonthsExp']
    setIsModalOpen(false)
    if (editSkillId) {
      values.id = editSkillId
    }
    if (isValidateNewSkill(values)) {
      createOrUpdateUserSkill(values)
    } else {
      message.error('Already subskill created, please update it from the data table below.')
    }
  }

  const isValidateNewSkill = (row) => {
    if (editSkillId) {
      return true
    }
    let flag = true
    userSkillList.forEach(s => {
      if (s.subSkillId === row.subSkillId) {
        flag = false
      }
    })
    return flag
  }

  const onPrimarySkillChange = (e) => {
    skillForm.setFieldsValue({
      isPrimary: e.target.checked,
    })
  }

  const onCertifiedChange = (e) => {
    skillForm.setFieldsValue({
      isCertified: e.target.checked,
    })
  }

  const createOrUpdateUserSkill = (data) => {
    axios.post(API_URL + `/api/user_skills/createOrUpdate`, data,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (editSkillId) {
          message.success("Updated the Skill successfully");
        } else {
          message.success("Created the Skill successfully");
        }
        sessionStorage.setItem('skillLastUpdated', JSON.stringify(moment().format('YYYY-MM-DD')));
        skillForm.resetFields()
        setEditSkillId(null)
        getUserSkills()
      })
      .catch(error => {
        message.error("Unexpected error occurred.");
        skillForm.resetFields()
      });
  }

  const deleteUserSkill = () => {
    axios.delete(API_URL + '/api/user_skills/deleteSkill/' + editSkillId,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        message.success("Deleted the Skill successfully");
        setIsDeleteModalOpen(false);
        setEditSkillId(null)
        getUserSkills()
      })
      .catch(error => {
        message.error("Unexpected error occurred.");
        skillForm.resetFields()
      });
  }

  const getUserSkills = () => {
    setLoading(true)
    axios.get(API_URL + `/api/user_skills/${userDetails.id}?t=${new Date().getTime()}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        setLoading(false)
        if (response.data && response.data.data) {
          response.data.data.forEach((d, i) => { d['key'] = i })
          setUserSkillList(response.data.data)
        }
      })
      .catch(error => {
        setLoading(false);
      })
  }

  const getUserProfileDetails = () => {
    setLoading(true)
    axios.get(API_URL + '/api/users/profileDetails/' + userDetails.id,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        setLoading(false)
        form.setFieldsValue({
          totalExperience: response.data.data[0].totalExperience + '',
          mobileTx: response.data.data[0].mobileTx + ''
        })
      })
      .catch(error => {
        setLoading(false);
      });
  }

  const updateUserProfileDetails = (data) => {
    setLoading(true)
    axios.post(API_URL + '/api/users/updateDetails/' + userDetails.id, data,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        setLoading(false)
        message.success("Updated details successfully");
      })
      .catch(error => {
        setLoading(false);
      });
  }

  const onChangeDepartment = (id) => {
    let allSkills = []
    allSkillsTemp.forEach(s => {
      if (s.departmentIdNm === id) {
        allSkills.push(s)
      }
    })
    setAllSkills(allSkills)
    setAllSubSkills([])
    skillForm.setFieldsValue({
      skillId: undefined,
      subSkillId: undefined
    })

  }

  const getAllDepSkillSubSkills = () => {
    axios.get(API_URL + `/api/skillbase/subskills/getAllSubSkills?t=${new Date().getTime()}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        setAllDepSkillSubSkills(response.data.data)
        let depArr = []
        let depSet = new Set()

        let skillArr = []
        let skillSet = new Set()

        response.data.data.forEach(s => {
          if (!depSet.has(s.sb_department.id)) {
            depSet.add(s.sb_department.id)
            depArr.push(s.sb_department)
          }

          if (!skillSet.has(s.sb_skill.id)) {
            skillSet.add(s.sb_skill.id)
            skillArr.push(s.sb_skill)
          }

        })

        setAllDepartment(depArr)
        setAllSkillsTemp(skillArr)

      })
      .catch(error => {
        console.log(error);
      });
  }
  const onChangeSkill = (id) => {
    let subSkills = []
    allDepSkillSubSkills.forEach(s => {
      if (s.skillIdNm === id) {
        subSkills.push(s)
      }
    })

    setAllSubSkills(subSkills)
    skillForm.setFieldsValue({
      subSkillId: undefined
    })
  }

  useEffect(() => {
    getUserSkills()
    getUserProfileDetails()
    getAllDepSkillSubSkills()
  }, [])
  return (
    <Auxiliary>
      <center className="gx-text-blue gx-pb-3 gx-pt-3"><h4>My Account</h4></center>

      <Row style={{ width: "auto" }}>
        <Col xl={8} lg={8} md={8} sm={24} xs={24}>
          <div className="detailsBlock equalHeight">

            <h4 className="headingColor1"> Name:<span>
              {(() => {
                if ("displayNameTx" in userDetails) {
                  return userDetails.displayNameTx
                }
              })()}
            </span></h4>
            <h4 className="headingColor1"> Emp ID:<span>
              {(() => {
                if ("employeeId" in userDetails) {
                  return userDetails.employeeId
                }
              })()}
            </span></h4>
            <h4 className="headingColor1"> Email:<span>
              {(() => {
                if ("emailTx" in userDetails) {
                  return userDetails.emailTx
                }
              })()}
            </span></h4>
            <h4 className="headingColor1"> Reporting Manager:<span>

              {(() => {
                if ("reportingManager" in userDetails && userDetails.reportingManager != null) {
                  if ("displayNameTx" in userDetails.reportingManager) {
                    return userDetails.reportingManager.displayNameTx
                  }
                }
              })()}
            </span></h4>
            <h4 className="headingColor1"> Designation:<span>
              {(() => {
                if ("designation" in userDetails) {
                  return userDetails.designation
                }
              })()}
            </span></h4>
            <h4 className="headingColor1"> Competency:<span className="text_uppercase">
              {(() => {
                if ("competency" in userDetails) {
                  return userDetails.competency
                }
              })()}
            </span>
            </h4>
            <h4 className="headingColor1"> Date Of Join:<span>
              {(() => {
                if ("joinedOn" in userDetails) {
                  let joinedOn = moment(userDetails.joinedOn);
                  let today = moment();
                  let diffDuration = moment.duration(today.diff(joinedOn));
                  return moment(userDetails.joinedOn).format('ll') + ' (' + diffDuration.years() + 'Y  ' + diffDuration.months() + 'M ' + diffDuration.days() + 'D)'
                }
              })()}
            </span>
            </h4>
          </div>
        </Col>
        <Col xl={16} lg={16} md={16} sm={24} xs={24}>
          <Row>
            <Form
              form={form}
              name="profileForm"
              onFinish={onProfileFinish}
              layout="inline"
              align="center"
            >
              <Row>
                <Col lg={8} md={8} sm={12} xs={12} >
                  <div className="gx-form-row0">
                    <h6 align="left">Total Years Of Experience</h6>
                    <Form.Item
                      name="totalExperience"
                      rules={[
                        () => ({
                          validator(_, value) {
                            let regEx = /^\d+(\.\d+)?$/
                            if (!value || !regEx.test(('' + value)) || ('' + value).trim() < 0.1 || ('' + value).trim() > 50) {
                              return Promise.reject("Please enter the valid experience, Max 50");
                            }
                            let ym = ('' + value).split('.')
                            if (ym.length > 1 && ym[1] > 11) {
                              return Promise.reject("Please enter the valid experience, Max 50");
                            }
                            return Promise.resolve();
                          },
                        })]}
                      hasFeedback>
                      <Input autoComplete="off" placeholder="Total Years Of Experience" />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={8} md={8} sm={12} xs={12} >
                  <div className="gx-form-row0">
                    <h6 align="left">Mobile Number</h6>
                    <Form.Item
                      name="mobileTx"
                      rules={[
                        () => ({
                          validator(_, value) {
                            let regEx = /^[6789]\d{9}$/
                            if (!value || (value && value.trim().length != 10) || isNaN(value) || !regEx.test(value)) {
                              return Promise.reject("Please enter the valid mobile number, start from 6/7/8/9");
                            }
                            return Promise.resolve();
                          },
                        })]}
                      hasFeedback>
                      <Input autoComplete="off" placeholder="Mobile Number" />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={24} xs={24}>
                  <div className="gx-form-row0">
                    <h6 align="left" className="d-heading">&nbsp;</h6>
                    <Form.Item >
                      <Button type="primary" className="add-btn scale-x" htmlType="submit">Save</Button>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={4} xs={4}></Col>
              </Row>
            </Form>
          </Row>
          <Row>
            <Col xl={20} lg={20} md={20} sm={16} xs={16}></Col>
            <Col xl={4} lg={4} md={4} sm={8} xs={8}>
              <Button type="link" className="scale-x" onClick={openSkillForm}><PlusOutlined></PlusOutlined> Add Skill</Button>
            </Col>
          </Row>
          <Row className="margin-auto">
            <Table className="gx-table-responsive" loading={loading} columns={userSkillListCols} dataSource={userSkillList} bordered={false} key={'id'} />
          </Row>
        </Col>
      </Row>

      <Modal closable={false} centered keyboard={false} maskClosable={false} title={editSkillId ? "Update Skill" : "Create Skill"}
        visible={isModalOpen} okText={editSkillId ? "Update" : "Create"} onOk={() => { skillForm.submit(); }}
        onCancel={() => { skillForm.resetFields(); setIsModalOpen(false); setEditSkillId(null) }}
        wrapClassName={`${editSkillId ? "modal-ticket-update" : "modal-ticket-create"}`}>
        <Row gutter={24} >
          <Form
            form={skillForm}
            name="skillForm"
            onFinish={onSkillFormFinish}
            layout="inline"
            align="center"
          >
            <Row gutter={24}>
              <Col lg={12} md={12} sm={12} xs={12} >
                <div className="gx-form-row0">
                  <h6 align="left">Department</h6>
                  <Form.Item
                    name="departmentId"
                    rules={[
                      {
                        required: true,
                        message: 'Select the Department',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select the Department"
                      filterOption={(input, option) => option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onChange={onChangeDepartment}
                      disabled={editSkillId}
                    >
                      {
                        allDepartment.length > 0 && allDepartment.map((dep, index) => (
                          <Option key={index} value={dep.id} name={dep.nameTx}>{dep.nameTx}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12} >
                <div className="gx-form-row0">
                  <h6 align="left">Skill</h6>
                  <Form.Item
                    name="skillId"
                    rules={[
                      {
                        required: true,
                        message: 'Select the Skill',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select the Skill"
                      filterOption={(input, option) => option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onChange={onChangeSkill}
                      disabled={editSkillId}
                    >
                      {
                        allSkills.length > 0 && allSkills.map((dep, index) => (
                          <Option key={index} value={dep.id} name={dep.nameTx}>{dep.nameTx}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12} >
                <div className="gx-form-row0">
                  <h6 align="left">Sub Skill</h6>
                  <Form.Item
                    name="subSkillId"
                    rules={[
                      {
                        required: true,
                        message: 'Select the sub Skill',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select the Sub Skill"
                      filterOption={(input, option) => option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      disabled={editSkillId}
                    >
                      {
                        allSubSkills.length > 0 && allSubSkills.map((dep, index) => (
                          <Option key={index} value={dep.id} name={dep.nameTx}>{dep.nameTx}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12} >
                <div className="gx-form-row0">
                  <h6 align="left">Self Assessment</h6>
                  <Form.Item
                    name="selfAssessment"
                    rules={[
                      {
                        required: true,
                        message: 'Select the Self Assessment',
                      },
                    ]} initialValue={1}
                  >
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select the Self Assessment"
                    >
                      <Option value={1} >Beginner</Option>
                      <Option value={2} >Intermediate</Option>
                      <Option value={3} >Professional</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6} >
                <div className="gx-form-row0">
                  <h6 align="left">Exp in Years</h6>
                  <Form.Item
                    name="relevantYearsExp"
                    initialValue={0}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if ((getFieldValue('relevantMonthsExp') === undefined || getFieldValue('relevantMonthsExp') === 0) && (value === undefined || value === 0)) {
                            return Promise.reject("Exp Years or Months is mandatory");
                          }
                          return Promise.resolve();
                        },
                      })]}
                  >
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select the Years"
                    >
                      {
                        relevantYears.length > 0 && relevantYears.map((year, index) => (
                          <Option key={index} value={year} name={year}>{year}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6} >
                <div className="gx-form-row0">
                  <h6 align="left">Exp in Months</h6>
                  <Form.Item
                    name="relevantMonthsExp"
                    initialValue={0}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if ((getFieldValue('relevantYearsExp') === undefined || getFieldValue('relevantYearsExp') === 0) && (value === undefined || value === 0)) {
                            return Promise.reject("Exp Years or Months is mandatory");
                          }
                          return Promise.resolve();
                        },
                      })]}
                  >
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select the Months"
                    >
                      {
                        relevantMonths.length > 0 && relevantMonths.map((month, index) => (
                          <Option key={index} value={month} name={month}>{month}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}>
                <div className="gx-form-row0">
                  <h6 align="left">Is Primary Skill</h6>
                  <Form.Item
                    name="isPrimary" valuePropName="checked">
                    <Checkbox onChange={onPrimarySkillChange}></Checkbox>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}>
                <div className="gx-form-row0">
                  <h6 align="left">Is Certified</h6>
                  <Form.Item
                    name="isCertified" valuePropName="checked">
                    <Checkbox onChange={onCertifiedChange}></Checkbox>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </Row>
        <Row gutter={24} >
          To add a new Sub Skill to the dropdown, Please contact: &nbsp;<b><a href="mailto: pmogov@gemini-us.com">pmogov@gemini-us.com</a></b>
        </Row>
      </Modal>

      <Modal closable={false} centered keyboard={false} maskClosable={false} title={"Delete Skill"} visible={isDeleteModalOpen} okText="Delete" onOk={() => { deleteUserSkill(); }}
        onCancel={() => { setIsDeleteModalOpen(false); }}
        wrapClassName={"modal-ticket-update"}>
        Are you sure to delete the skill ?
      </Modal>

    </Auxiliary>
  );
};
export default MyAppsHome;